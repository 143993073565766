/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import SearchField from '../../../../components/atoms/Search/Search';
import Typography from '../../../../components/atoms/Typography/Typography';
import colors from '../../../../utils/colors/colors';
import './TableFilter.css';

/**
 *
 * @param {object} props - required props in TableFilter
 * @returns {React.ReactElement} --
 */
const TableFilter = (props) => {
  const { onSelect } = props;
  const [list, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectField, setSelectField] = useState(true);
  const [position, setPosition] = useState(1);

  /**
   *
   * @param {Array} res --
   */
  const mapArr = (res) => {
    // const tempArr = res?.map((val) => {
    //   return val?.Type;
    // });
    setFilterList(res);
    setList(res);
  };
  const url = process.env.REACT_APP_URL2;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/getGeneralMasterCodeList`,

      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      mapArr(response?.data.data);
    });
  }, [url]);
  /**
   *
   * @param {Array} e --
   */
  const handleChange = (e) => {
    if (e.target.value.trim() !== '') {
      const newArray = list.filter((item) => {
        return item.toLowerCase().includes(e.target.value.trim().toLowerCase());
      });
      setFilterList(newArray);
    } else {
      setFilterList(list);
    }
  };
  /**
   *
   * @param {number} idx - pa<Testssing index
   */
  const onClickHandle = (idx) => {
    setSelectField(true);
    setPosition(idx);
  };
  return (
    <div>
      <Grid p="10px">
        <SearchField onChangeHandler={handleChange} customClass="searchClass" />
      </Grid>
      <div className="valueTextDiv">
        {filterList?.map((value, index) => {
          const key = index;
          return (
            <div
              key={key}
              className="valueText"
              onClick={() => {
                onClickHandle(index);
                onSelect(value);
              }}
              aria-hidden
            >
              <Typography
                text={value}
                type="link"
                customClass="filterText"
                colorType={
                  position === index && selectField ? 'quinary' : 'primary'
                }
                customStyle={{
                  fontFamily: 'Poppins_semibold',
                  background:
                    position === index && selectField
                      ? colors.blue.senary
                      : 'quaternary'
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableFilter;
