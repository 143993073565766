/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './CustomDropdown.css';
import CustomTypography from '../Typography/Typography';
/**
 *
 * @param {object} props - props required in normal dropdown
 * @returns {React.ReactElement} - returns the normal dropdown
 */
const CustomDropdown = (props) => {
  const {
    data,
    handleChange,
    value,
    disabled,
    name,
    label,
    customClass,
    requiredField
  } = props;

  return (
    <Box className="box" item md={12} sm={12}>
      <Box>
        <CustomTypography
          type="caption"
          text={label}
          customClass="labelText"
          colorType="senary"
          requiredField={requiredField}
        />
      </Box>
      <FormControl
        className="formControl"
        fullWidth
        disabled={disabled}
        size="small"
      >
        {/* <InputLabel id="demo-simple-select-label" className="inputlabelText">
          Select
        </InputLabel> */}
        <Select
          size="small"
          name={name}
          IconComponent={ExpandMoreIcon}
          fullWidth
          labelId="demo-select-small"
          id="demo-select-small"
          // input={
          //   <OutlinedInput
          //     notched
          //     name="age"
          //     id="outlined-age-always-notched"
          //   />
          // }
          value={value}
          data={data}
          className={`${customClass} ${disabled && 'disable'} customDropdown`}
          onChange={(e) => handleChange(e)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <CustomTypography text="Select" />
          </MenuItem>
          {data?.length &&
            data?.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id} className="menuItem">
                  {item?.value}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomDropdown;
CustomDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  customClass: PropTypes.string,
  requiredField: PropTypes.bool
};
CustomDropdown.defaultProps = {
  disabled: false,
  label: '',
  customClass: '',
  requiredField: false
};
