/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import AddNew from '../../../../components/organisms/AddNew/AddNew';
import {
  RelativeChargingEntries,
  ResourceRequirementEntries,
  AssociatedCharges,
  SynonmysEntries,
  AliasCodeEntries,
  DepositRequirementEntries,
  PackageDetailsEntries
} from './entries';
import './tabs.css';

/**
 * @param {object} props - required props in Tab Data componnet
 * @returns {React.ReactElement} return App bar and component
 */
const TabData = (props) => {
  const {
    handleSubmit,
    onSubmit,
    Controller,
    control,
    errors,
    watchField,
    watchDateField,
    tab,
    watchSwitchField
  } = props;

  const [TabEntries, setTabEntries] = React.useState([]);
  const [resourceRole, setResourceRole] = React.useState([]);
  const [rvUnitcode, setRvUnitCode] = React.useState([]);
  const [synType, setSynType] = React.useState([]);
  /**
   *
   * @param {*} data
   * @returns
   */
  const getRelativeChargingEntries = useCallback(
    (data) => {
      return data.map((value) => {
        const tmpValue = value;

        if (tmpValue.name === 'rvucode') {
          tmpValue.dataList = _.assign(rvUnitcode);

          return tmpValue;
        }
        return tmpValue;
      });
    },
    [rvUnitcode]
  );
  /**
   *
   * @param {*} data
   * @returns
   */
  const getResourceRequirementEntries = useCallback(
    (data) => {
      return data.map((value) => {
        const tmpValue = value;
        if (tmpValue.name === 'resourcerole') {
          tmpValue.data = _.assign(resourceRole);

          return tmpValue;
        }
        return tmpValue;
      });
    },
    [resourceRole]
  );
  /**
   *
   * @param {*} data
   * @returns
   */
  const getSynonmysEntries = useCallback(
    (data) => {
      return data.map((value) => {
        const tmpValue = value;
        if (tmpValue.name === 'synonymtype') {
          tmpValue.dataList = _.assign(synType);

          return tmpValue;
        }
        return tmpValue;
      });
    },
    [synType]
  );
  useEffect(() => {
    if (watchSwitchField === true) {
      setTabEntries([
        {
          entry: PackageDetailsEntries,
          // header: 'RELATIVE CHARGING BASIS',
          arrayName: 'packageDetails'
        },
        {
          entry: DepositRequirementEntries,
          header: 'DEPOSIT REQUIREMENTS',
          arrayName: 'depositRequirement'
        },
        {
          entry: SynonmysEntries,
          header: 'SYNONMYS',
          arrayName: 'chargesynonyms'
        },
        {
          entry: AliasCodeEntries,
          header: 'ALIAS CODE MAPPING',
          arrayName: 'aliasservmapping'
        }
      ]);
    } else {
      const relativeChargingEntries = getRelativeChargingEntries(
        RelativeChargingEntries
      );
      const resourceRequirementEntries = getResourceRequirementEntries(
        ResourceRequirementEntries
      );
      const synonmysEntries = getSynonmysEntries(SynonmysEntries);
      setTabEntries([
        {
          entry: relativeChargingEntries,
          header: 'RELATIVE CHARGING BASIS',
          arrayName: 'relchargebasis'
        },
        {
          entry: resourceRequirementEntries,
          header: 'RESOURCE REQUIREMENTS',
          arrayName: 'requiredresources'
        },
        {
          entry: AssociatedCharges,
          header: 'ASSOCIATED CHARGES',
          arrayName: 'assoccharges'
        },
        {
          entry: DepositRequirementEntries,
          header: 'DEPOSIT REQUIREMENTS',
          arrayName: 'depositRequirement'
        },
        {
          entry: synonmysEntries,
          header: 'SYNONMYS',
          arrayName: 'chargesynonyms'
        },
        {
          entry: AliasCodeEntries,
          header: 'ALIAS CODE MAPPING',
          arrayName: 'aliasservmapping'
        }
      ]);
    }
  }, [
    getRelativeChargingEntries,
    getResourceRequirementEntries,
    getSynonmysEntries,
    watchSwitchField
  ]);
  /**
   *
   */
  const apiCall = () => {
    const url = process.env.REACT_APP_URL2;
    axios({
      method: 'get',
      url: `${url}/masterDropDown/RVUNIT`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const tempArr = [];

      response?.data.data.map((val) => {
        return tempArr.push({
          // eslint-disable-next-line no-underscore-dangle
          id: val._id,
          value: val.code,
          data: val?.shortdesc
        });
      });
      setRvUnitCode(tempArr);
    });
    axios({
      method: 'get',
      url: `${url}/masterDropDown/PRACTROLE`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const tempArr = [];

      response?.data.data.map((val) => {
        return tempArr.push({
          // eslint-disable-next-line no-underscore-dangle
          id: val._id,
          value: val.code
        });
      });

      setResourceRole(tempArr);
    });
    axios({
      method: 'get',
      url: `${url}/masterDropDown/SYNONYMTYPE`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const tempArr = [];

      response?.data.data.map((val) => {
        return tempArr.push({
          // eslint-disable-next-line no-underscore-dangle
          id: val._id,
          value: val.code,
          data: val?.shortdesc
        });
      });

      setSynType(tempArr);
    });
  };
  React.useEffect(() => {
    apiCall();
  }, []);

  return (
    <div>
      <Grid
        container
        direction="row"
        item
        md={12}
        xs={12}
        className="tabContent"
      >
        <Grid item md={12} pl={1} xs={12}>
          {TabEntries?.map((value, idx) => {
            const key = idx;
            const isShow = tab === key;
            return (
              isShow && (
                <AddNew
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  ArrayName={value.arrayName}
                  addNewHead={value.header}
                  Controller={Controller}
                  control={control}
                  errors={errors}
                  entries={value.entry}
                  waatchField={watchField}
                  watchDateField={watchDateField}
                  customClass="addNewHead"
                  valueDefault={{
                    maxnoofresources: 1,
                    ismandatory: false
                  }}
                />
              )
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default TabData;

TabData.propTypes = {
  Controller: PropTypes.node,
  control: PropTypes.node,
  errors: PropTypes.shape(PropTypes.Object),
  watchField: PropTypes.func,
  watchDateField: PropTypes.shape(PropTypes.arrayOf),
  tab: PropTypes.number,
  watchSwitchField: PropTypes.bool
};
TabData.defaultProps = {
  Controller: <> </>,
  control: <> </>,
  errors: {},
  watchField: () => null,
  watchDateField: [],
  tab: 0,
  watchSwitchField: false
};
