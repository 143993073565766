import React from 'react';
import { Menu, ListItemText, ListItem } from '@mui/material';
import PropTypes from 'prop-types';
import './MultiSelect.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '../Typography/Typography';

/**
 *
 * @param {object} props - required props in MultiSelect component
 * @returns {React.ReactElement} returns the MultiSelect component
 */
const MultiSelect = (props) => {
  const { listItemData, onClickList } = props;
  /**
   *
   */
  const [arrowControls, setArrowControls] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  /**
   *
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * @param {object} item - passing item
   */
  const onClickListItem = (item) => {
    setArrowControls(false);
    onClickList(item);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      sx={{ top: -55 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {listItemData?.map((item) => {
        return (
          <ListItem onClick={handleMenuClose} className="listItem">
            <ListItemText
              className="listText"
              onClick={() => onClickListItem(item)}
            >
              {item.list}
            </ListItemText>
          </ListItem>
        );
      })}
    </Menu>
  );
  /**
   * @name handleProfileMenuOpen
   * @param {*} event describes the event
   */
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowControls(!arrowControls);
  };
  return (
    <>
      <div className="container" onClick={handleProfileMenuOpen} aria-hidden>
        <div className="Add">
          <Typography
            text="+ Add New"
            type="link"
            colorType="quinary"
            customClass="addText"
          />
        </div>
        <div className="dropdownVal">
          {arrowControls === true ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" />
          )}
        </div>
      </div>
      {renderMenu}
    </>
  );
};
export default MultiSelect;
MultiSelect.propTypes = {
  listItemData: PropTypes.arrayOf(PropTypes.objectOf),
  onClickList: PropTypes.func
};
MultiSelect.defaultProps = {
  listItemData: [],
  onClickList: () => null
};
