import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider
} from '@mui/material';
import PropTypes from 'prop-types';
import './Modal.css';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import customIcons from '../../../utils/icons/icons';
import CustomButton from '../../atoms/CustomButton/Button';
import colors from '../../../utils/colors/colors';
/**
 *  @param {object} props - required to define props of modal
 * @name  CustomModal
 * @returns {React.ReactElement} - returns Typography component
 */
const CustomModal = (props) => {
  const {
    openModal,
    modalTitle,
    modalContent,
    actionType,
    onClose,
    modalHeadAction,
    onClearField,
    onAddField,
    onBulkadd
  } = props;
  return (
    <ClickAwayListener onClickAway={() => onClose(false)}>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid>
          <Grid justifyContent="space-between" display="flex">
            <DialogTitle className="modTitle">{modalTitle}</DialogTitle>
            <Grid display="flex">
              {modalHeadAction && (
                <Grid
                  flexDirection="row"
                  display="flex"
                  // justifyContent="space-between"
                  pt={0.5}
                >
                  <div className="modalHeadAction">
                    <Grid pr={1.1}>
                      <CustomButton
                        variants="contained"
                        type="cancel"
                        btnTitle="Clear"
                        TitleColor="inActive"
                        onClickHandle={() => onClearField()}
                      />
                    </Grid>

                    <CustomButton
                      variants="contained"
                      type="info"
                      btnTitle="Add"
                      TitleColor="quaternary"
                      onClickHandle={() => onAddField()}
                    />
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      border: '1px solid',
                      borderColor: colors.blue.tertiary
                    }}
                  />
                  <div className="bulkEditIconDiv">
                    <img
                      src={customIcons.BulkAdd}
                      alt="bulkAdd"
                      className="bulkEditIcon"
                      onClick={() => onBulkadd()}
                      aria-hidden
                    />
                  </div>
                  <Divider
                    sx={{
                      border: '1px solid',
                      borderColor: colors.blue.tertiary
                    }}
                  />
                </Grid>
              )}
              <Grid onClick={() => onClose(false)} padding="16px 10px 4px 10px">
                <img
                  src={customIcons.ModalClose}
                  alt="close"
                  className="closeModal"
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider
            sx={{
              border: '1px solid',
              borderColor: colors.blue.tertiary
            }}
          />
          <DialogContent>{modalContent}</DialogContent>
          <DialogActions>{actionType}</DialogActions>
        </Grid>
      </Dialog>
    </ClickAwayListener>
  );
};
export default CustomModal;
CustomModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.node.isRequired,
  actionType: PropTypes.node,
  onClose: PropTypes.func,
  modalHeadAction: PropTypes.bool,
  onClearField: PropTypes.func,
  onAddField: PropTypes.func,
  onBulkadd: PropTypes.func
};
CustomModal.defaultProps = {
  modalTitle: '',
  actionType: <> </>,
  onClose: () => {},
  modalHeadAction: false,
  onClearField: () => null,
  onAddField: () => null,
  onBulkadd: () => null
};
