import { Grid, Paper, Divider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddNew from './AddNew/addNew';
import EnhancedTable from '../../../components/organisms/SortTable/sortTable';
import TableFilter from './TableFilter';
import CustomDrawer from '../../../components/molecules/Drawer';
import './codeSet.css';
import Toaster from '../../../components/atoms/Toaster/Toaster';

/**
 *
 * @returns {React.ReactElement} - returns the
 */
const ChargeCodeSet = () => {
  const [filterTabledata, setFilterTableData] = useState('ACCOUNTENTITYTYPE');
  const [msg, setMsg] = React.useState('');
  const [successType, setType] = React.useState('');
  const [additionalColumn, setAdditionalColumns] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [editId, setEditId] = useState('');
  const [formDefaultValues, setDefaultValues] = useState({});
  const [rowData, setRowData] = useState([]);
  const [gmDefId, setGmDefId] = useState([]);
  const [tableIdsData, setTableIdsData] = useState([]);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [gmIndividualID, setGmIndividualID] = React.useState(false);
  const [additionalColumnsComponent, setAdditionalColumnsComponent] =
    React.useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const headCells = [
    { id: 'Sno', label: 'S.No' },
    {
      id: 'Code',
      label: 'Code'
    },
    {
      id: 'ShortDescription',

      label: 'Short Description'
    },
    {
      id: 'LongDescription',

      label: 'Long Description'
    },
    {
      id: 'Status',

      label: 'Status'
    },
    {
      id: 'Action',
      label: 'Action'
    }
  ];

  const url = process.env.REACT_APP_URL2;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/getGeneralMasterCodeListById/${filterTabledata}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const filterArr = [];
      const tableIds = [];
      const gmDefinitionIds = [];
      if (Array.isArray(response?.data.data) === false) {
        setRowData(filterArr);
        setGmIndividualID(response?.data?.data?._id);
      } else {
        response?.data.data.map((item, index) => {
          gmDefinitionIds.push(item.GMdefinition);
          // eslint-disable-next-line no-underscore-dangle
          tableIds.push(item._id);
          return filterArr.push({
            Sno: index + 1,
            Code: item.code,
            ShortDescription: item.shortdesc,
            LongDescription: item.display,
            Status: item.status,
            edit: ''
          });
        });
        setGmDefId(gmDefinitionIds);
        setRowData(filterArr);
        setTableIdsData(tableIds);
      }
    });
  }, [url, filterTabledata]);
  /**
   *
   * @param {*} type - passing type
   * @param {number} idx - passing index
   */
  const toggleDrawer = async (type, idx) => {
    if (type === 'add') {
      let id;
      if (gmIndividualID.length) {
        id = gmIndividualID;
      } else {
        [id] = gmDefId;
      }
      setIsFormEdit(false);
      setDefaultValues({});
      axios({
        method: 'get',
        url: `${url}/getGeneralMasterGMdefinitionById/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          response.data?.data?.additionalcolumns?.map(async (val) => {
            axios({
              method: 'post',
              url: `https://arangodbservice.dev.ainqaplatform.in/api/read_documents`,
              data: {
                db_name: 'primarycare',
                entity: 'CodingMaster',
                filter: `CodingMaster._id== '${val?.columntype}' && CodingMaster.activestatus == true  && CodingMaster.Type == 'QDMDATATYPE'`,
                return_fields: "keep(CodingMaster,'display')"
              },
              headers: {
                'Content-Type': 'application/json'
              }
            }).then((res) => {
              setAdditionalColumnsComponent(res?.data?.result);
            });
          });
          setAdditionalColumns(response.data?.data?.additionalcolumns);
          setDefaultValues({});
        })
        .catch((err) => {
          setMsg(err);
          setType('failure');
          setSuccessMsg(true);
        });
    }
    if (type === 'edit') {
      setIsFormEdit(true);
      setEditId(tableIdsData[idx - 1]);
      axios({
        method: 'get',
        url: `${url}/getCodingMasterById/${tableIdsData[idx - 1]}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          const data = response.data?.data;
          setDefaultValues(data);
        })
        .catch((err) => {
          setMsg(err);
          setType('failure');
          setSuccessMsg(true);
        });
    }
    setDrawerOpen(!drawerOpen);
  };

  /**
   *
   * @param {*} data - passing data
   */
  const onSubmitData = (data) => {
    const customData = data;
    customData.Type = filterTabledata;
    customData.gmconfigvalues = {};
    if (isFormEdit) customData._id = editId;
    if (isFormEdit) {
      axios({
        method: 'post',
        url: `${url}/updateCodingMaster`,
        headers: {
          'Content-Type': 'application/json'
        },
        customData
      })
        .then((response) => {
          if (response) {
            setDefaultValues({});
            setType('success');
            setMsg('Data updated successfully');
            setSuccessMsg(true);
          }
        })
        .catch((err) => {
          setMsg(err);
          setType('failure');
          setSuccessMsg(true);
        });
    } else {
      axios({
        method: 'post',
        url: `${url}/createCodingMaster`,
        headers: {
          'Content-Type': 'application/json'
        },
        customData
      })
        .then((response) => {
          if (response) {
            setType('success');
            setMsg('Data added successfully');
            setSuccessMsg(true);
          }
        })
        .catch((err) => {
          setMsg(err);
          setType('failure');
          setSuccessMsg(true);
        });
    }
  };
  /**
   * @param {*} event - passing event
   * @param {*} reason - passing reason
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessMsg(false);
  };
  return (
    <Grid container spacing={2} className="customstyleGrid">
      {successMsg && (
        <Toaster
          type={successType}
          open={successMsg}
          text={msg}
          handleClose={handleClose}
        />
      )}
      <Grid item md={2}>
        <Paper elevation={3} className="addGrid">
          <Grid display="flex" justifyContent="flex-start" pt={1.1}>
            <AddNew />
          </Grid>
          <Divider className="borderBottom" />
          <Grid pt={1}>
            <TableFilter onSelect={(d) => setFilterTableData(d)} />
          </Grid>
        </Paper>
      </Grid>

      <Grid item md={10}>
        <EnhancedTable
          headCells={headCells}
          rowData={rowData}
          tableId="400697630"
          isDrawer
          onHandleAction={(idx) => toggleDrawer('add', idx)}
          onOpenModel={(idx) => toggleDrawer('edit', idx)}
        />
      </Grid>
      <CustomDrawer
        toggleDrawerDirection={['right']}
        handleDrawer={() => toggleDrawer('edit')}
        openDrawer={drawerOpen}
        isFormEdit={isFormEdit}
        onCloseDrawer={(d) => setDrawerOpen(d)}
        onSubmitData={(data) => onSubmitData(data)}
        defaultValues={formDefaultValues}
        drawerName={filterTabledata}
        additionalColumnsComponent={additionalColumnsComponent}
        additionalColumn={additionalColumn}
      />
    </Grid>
  );
};

export default ChargeCodeSet;
