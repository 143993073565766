/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import CustomTypography from '../../atoms/Typography/Typography';
import SearchField from '../../atoms/Search/Search';
import CustomButton from '../../atoms/CustomButton/Button';
import Pagination from './Pagination/Pagination';
import './sortTable.css';
import EnhancedTableHead from './EnhancedTableHead/EnhancedTableHead';
import StatusComponent from '../../atoms/StatusComponent/statusComponent';
import customIcons from '../../../utils/icons/icons';

/**
 *
 * @param  {*} a - passing a value
 * @param {*} b - passing a value
 * @param  {*} orderBy - checks the order of array of object
 * @returns {*} - descending order list
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param {*} order - checks the order of array of object
 * @param  {*} orderBy - checks the order of array of object
 * @returns {*} - returns the comparing data
 */
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
/**
 *
 * @param {Array} array - passing the array of data
 * @param {*} comparator - compares the value
 * @returns {Array} - returns the sorted list
 */
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 *
 * @param {object} props - required props in EnhancedTable
 * @returns {React.ReactElement} - returns the table sort component
 */
const EnhancedTable = (props) => {
  const { headCells, rowData, onHandleAction, tableId, onOpenModel, isDrawer } =
    props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState(rowData);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableConfig, setTableConfig] = useState([]);
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    setRows(rowData);
  }, [rowData]);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${url}/read_documents`,
      data: {
        db_name: 'RCM',
        entity: 'CustomTable',
        filter: `CustomTable._id == "CustomTable/${tableId}"`,
        return_fields: 'CustomTable'
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      setTableConfig(response?.data.result[0].children);
    });
  }, [url, tableId]);
  /**
   *
   * @param {*} event - onchange event
   * @param {*} property - passing property
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  /**
   *
   * @param {*} event - onClick event on sort
   */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
    }
    setSelected([]);
  };
  /**
   *
   * @param {*} event - onclick event
   * @param {*} name - passing the sortTableData name
   */
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  /**
   *
   * @param {*} e - passing onchange event
   */
  const onChangeHandlerSearch = (e) => {
    const filters = [];
    const initialData = [...rowData];

    if (e.target.value) {
      initialData.map((item) => {
        if (item.Code.toLowerCase().includes(e.target.value.toLowerCase())) {
          filters.push(item);
        }
        return false;
      });
      setRows(filters);
    } else if (!e.target.value) {
      setRows(initialData);
    }
  };
  /**
   *
   * @param {*} name - passing name key
   * @returns {number} - selected name index
   */
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  /**
   *
   * @param {*} newPage -- page Number
   */
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  /**
   *
   * @param {number} event -- Row
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event);
    setPage(0);
  };
  return (
    <Box>
      <Paper className="mainTablePaper">
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className="customGrid"
        >
          <Grid>
            {tableConfig?.TitleBar?.Active && (
              <Grid className="tableActionGrid">
                {tableConfig?.TitleBar?.Title?.Active && (
                  <CustomTypography
                    text={tableConfig?.TitleBar?.Title?.DisplayText?.Text}
                    type="caption"
                    colorType="primary"
                    customClass="tableHeader"
                  />
                )}
                <Grid className="tableAction">
                  {tableConfig?.TitleBar?.SearchBox?.Active && (
                    <Grid pr={1}>
                      <SearchField
                        onChangeHandler={(d) => onChangeHandlerSearch(d)}
                        placeholder={
                          tableConfig?.TitleBar?.SearchBox?.PlaceHolder?.Text
                        }
                        handleClear={() => setRows(rowData)}
                      />
                    </Grid>
                  )}
                  {tableConfig?.TitleBar?.Button?.Active &&
                    (isDrawer ? (
                      <CustomButton
                        variants="contained"
                        type="secondary"
                        btnTitle={
                          tableConfig?.TitleBar?.Button?.DisplayText?.Text
                        }
                        TitleColor="quaternary"
                        onClickHandle={() => onHandleAction()}
                        startIcon
                        iconType="plus"
                        customClass="btnActionTable"
                      />
                    ) : (
                      <NavLink
                        to="addchargemaster"
                        style={{ textDecoration: 'none' }}
                      >
                        <CustomButton
                          variants="contained"
                          type="secondary"
                          btnTitle={
                            tableConfig?.TitleBar?.Button?.DisplayText?.Text
                          }
                          TitleColor="quaternary"
                          onClickHandle={() => onHandleAction()}
                          startIcon
                          iconType="plus"
                          customClass="btnActionTable"
                        />
                      </NavLink>
                    ))}
                </Grid>
              </Grid>
            )}
            <TableContainer className="tableContainer">
              <Table stickyHeader aria-label="sticky table" className="table">
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody className="tableBody">
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {Object.entries(row)?.map(([key, value]) => {
                            return (
                              <TableCell
                                component="th"
                                id={labelId}
                                // className="tableCell"
                                className={
                                  key === 'Description'
                                    ? 'descwidth'
                                    : 'tableCell'
                                }
                              >
                                {key === 'Status' ? (
                                  <StatusComponent enable={value === true} />
                                ) : key === 'edit' ? (
                                  !isDrawer ? (
                                    <NavLink
                                      to={`${
                                        tableConfig?.Table?.EditPath
                                      }/?id=${row?.id}&isEdit=${true}`}
                                      style={{ textDecoration: 'none' }}
                                    >
                                      <img
                                        src={customIcons.Edit}
                                        alt="edit"
                                        aria-hidden
                                        className="editIcon"
                                      />
                                    </NavLink>
                                  ) : (
                                    <img
                                      src={customIcons.Edit}
                                      alt="edit"
                                      aria-hidden
                                      className="editIcon"
                                      onClick={() => onOpenModel(row?.Sno)}
                                    />
                                  )
                                ) : (
                                  <CustomTypography
                                    type="caption"
                                    text={value}
                                    customClass={key === 'Code' && 'uppercase'}
                                  />
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid>
            {tableConfig?.Footer?.Active && (
              <Pagination
                defaultPageRows={5}
                pageCount={rows.length}
                totalCount={rows.length}
                getRow={(x) => handleChangeRowsPerPage(x)}
                getPageNo={(val) => handleChangePage(val - 1)}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default EnhancedTable;
EnhancedTable.propTypes = {
  onHandleAction: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.objectOf),
  rowData: PropTypes.arrayOf(PropTypes.objectOf),
  tableId: PropTypes.string,
  onOpenModel: PropTypes.func,
  isDrawer: PropTypes.bool
};
EnhancedTable.defaultProps = {
  onHandleAction: () => null,
  headCells: [],
  rowData: [],
  tableId: '',
  onOpenModel: () => null,
  isDrawer: false
};
