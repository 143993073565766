const headCells = [
  { id: 'id', label: 'S.No' },
  {
    id: 'Code',
    label: 'Charge Code'
  },
  {
    id: 'Description',
    label: 'Description'
  },
  {
    id: 'CodeSet',

    label: 'Charge Code Set'
  },
  {
    id: 'Category',

    label: 'Charge Category'
  },
  {
    id: 'Type',

    label: 'Charge Type'
  },
  {
    id: 'Group',

    label: 'Charge Group'
  },
  {
    id: 'Status',

    label: 'Status'
  },
  {
    id: 'Action',

    label: 'Action'
  }
];

export default headCells;
