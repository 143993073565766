/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { KeyboardArrowDown } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '../../atoms/Typography/Typography';
import './appBar.css';
/**
 * @param  {object} props - required props
 * @returns {React.ReactElement} return the app bar component
 */
const AppBarHeader = (props) => {
  const { onChooseListItem } = props;
  const [appBarContent, setAppBarContent] = useState([]);
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${url}/read_documents`,
      data: {
        db_name: 'RCM',
        entity: 'appBar',
        return_fields: 'appBar'
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      setAppBarContent(response?.data.result);
    });
  }, [url]);
  const [anchorE2, setAnchorE2] = useState(null);

  /**
   * @name handleProfileMenuOpen
   * @param {*} event describes the event
   */
  const handleProfileMenuOpen = (event) => {
    setAnchorE2(event.currentTarget);
    const rotate = document.querySelector('.box');
    rotate.classList.add('transform');
  };

  /**
   * @name handleClose
   */
  const handleClose = () => {
    setAnchorE2(null);
  };

  /**
   * @name handleMenuClose
   */
  const handleMenuClose = () => {
    setAnchorE2(null);
    const menuClose = document.querySelector('.box');
    menuClose.classList.remove('transform');
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="appBarContainer">
      {appBarContent?.map((data, id) => {
        const Id = id;
        const {
          bgColor,
          title,
          // logoIcon,
          version,
          profileItems,
          showMenu,
          profileDetails
        } = data.children;
        return (
          <AppBar key={Id} position="fixed" sx={{ bgcolor: bgColor }}>
            <Grid
              item
              md={12}
              className="appBarBackgroundColor"
              container
              direction="row"
              alignItems="center"
            >
              <Grid
                item
                xs={2}
                container
                direction="row"
                className="first_container"
                alignItems="center"
              >
                {/* {logoIcon.Active && (
                  <Grid
                    item
                    xs={2}
                    style={{ color: logoIcon.color }}
                    className="iconContainer"
                  >
                    <img src={logoIcon.icon} alt="" />
                  </Grid>
                )} */}
                {title.Active && (
                  <Grid item xs={10} className="tile_view">
                    <Typography
                      type="caption"
                      text={title.DisplayText.Text}
                      colorType="quaternary"
                      customStyle={title.Typography}
                    />
                  </Grid>
                )}
              </Grid>
              {version.Active && (
                <Grid item xs={7.5} className="tab_flow">
                  <Typography
                    type="caption"
                    text={version.DisplayText.Text}
                    colorType="quaternary"
                    customStyle={version.Typography}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={2.5}
                container
                direction="row"
                alignItems="center"
                className="profile_block"
              >
                <Grid item xs={8} className="profile_container">
                  {profileDetails.userName.Active && (
                    <Grid className="profile_name">
                      <Typography
                        type="caption"
                        text={profileDetails.userName.DisplayText.Text}
                        colorType="quaternary"
                        customStyle={profileDetails.userName.Typography}
                      />
                    </Grid>
                  )}
                  {profileDetails.userDetails.Active && (
                    <Grid className="sub_profile">
                      <Typography
                        type="caption"
                        text={profileDetails.userDetails.DisplayText.Text}
                        colorType="quaternary"
                        customStyle={profileDetails.userDetails.Typography}
                      />
                    </Grid>
                  )}
                </Grid>
                {profileDetails.profile.Active && (
                  <Grid item xs={2.5} className="profile">
                    <img src={profileDetails.profile.image} alt="" />
                  </Grid>
                )}
                {profileDetails.profile.Active && (
                  <Grid
                    item
                    xs={1}
                    onClick={handleProfileMenuOpen}
                    style={{ cursor: 'pointer' }}
                  >
                    <KeyboardArrowDown />
                  </Grid>
                )}
                {showMenu && (
                  <Box sx={{ display: { xs: 'flex', md: 'flex', sm: 'flex' } }}>
                    <Menu
                      id="menu-appBar"
                      anchorEl={anchorE2}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorE2)}
                      onClose={handleClose}
                    >
                      <MenuItem>
                        <Grid>
                          {profileItems?.map((item, index) => {
                            const key = index;
                            return (
                              <Grid
                                key={key}
                                onClick={() => onChooseListItem(item.id)}
                              >
                                <MenuItem
                                  onClick={handleMenuClose}
                                  className="menuIems"
                                >
                                  <NavLink
                                    to="/logout"
                                    style={{ textDecoration: 'none' }}
                                    className="navLink"
                                  >
                                    {item.menuIcon.Active && (
                                      <ListItemIcon>
                                        <img
                                          src={item.menuIcon.image}
                                          alt=""
                                          className="iconStyling"
                                        />
                                      </ListItemIcon>
                                    )}
                                    <ListItemText>
                                      <Typography
                                        type="caption"
                                        text={item.menuText.DisplayText.Text}
                                        colorType="quaternary"
                                        customStyle={item.menuText.Typography}
                                      />
                                    </ListItemText>
                                  </NavLink>
                                </MenuItem>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
              </Grid>
            </Grid>
          </AppBar>
        );
      })}
    </Box>
  );
};

export default AppBarHeader;
AppBarHeader.propTypes = {
  onChooseListItem: PropTypes.func
};
AppBarHeader.defaultProps = {
  onChooseListItem: () => null
};
