import React from 'react';
import { Switch, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTypography from '../Typography/Typography';
import './switch.css';
/**
 * @name CustomSwitch
 * @param {string} props --
 * @returns {React.ReactElement} returns the switch component
 */
const CustomSwitch = (props) => {
  const { switchColor, onChange, value, name, disabled, defaultChecked, text } =
    props;
  return (
    <Grid>
      <Stack direction="column" spacing={1} alignItems="center">
        <CustomTypography
          text={text}
          type="caption"
          colorType="senary"
          customClass="packageLabelText"
        />
        <Switch
          color={switchColor}
          size="large"
          name={name}
          inputProps={{ 'aria-label': 'ant design' }}
          onChange={(checked) => onChange(checked)}
          value={value}
          disabled={disabled}
          defaultChecked={defaultChecked && defaultChecked}
        />
      </Stack>
    </Grid>
  );
};
export default CustomSwitch;
CustomSwitch.propTypes = {
  text: PropTypes.string,
  switchColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool
};
CustomSwitch.defaultProps = {
  text: '',
  switchColor: '',
  name: '',
  disabled: false,
  defaultChecked: false,
  value: ''
};
