/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from 'react';
import './MenuSelect.css';
import PropTypes from 'prop-types';
import CustomButton from '../CustomButton/Button';

/**
 * @name CustomTextField
 * @param {object} props - required props in text field component
 * @returns {React.ReactElement} return the TextField Component
 */
const MenuSelect = (props) => {
  const { btnName, handleClick, defaultSelect } = props;
  const [color, setColor] = React.useState(defaultSelect);

  /**
   *
   */
  const onSelect = () => {
    setColor(!color);
    handleClick(!color);
  };
  React.useEffect(() => {
    if (defaultSelect) {
      setColor(true);
    } else setColor(false);
  }, [defaultSelect]);

  return (
    <CustomButton
      variants="contained"
      btnTitle={btnName}
      customClass="menuBtn"
      type={color ? 'info' : 'disable'}
      TitleColor={color ? 'quaternary' : 'dark'}
      onClickHandle={() => onSelect()}
    />
  );
};
export default MenuSelect;
MenuSelect.propTypes = {
  btnName: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};
