import React from 'react';
import { Divider, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import CustomDropdown from '../../../../../components/atoms/CustomDropdown/Dropdown';
import CustomTextField from '../../../../../components/atoms/TextInput';
import CustomDatePicker from '../../../../../components/atoms/Pickers/DatePicker';
// import BulkAdd from './bulkadd';
import CustomSwitch from '../../../../../components/atoms/CustomSwitch/switch';
import TreeViewStatus from '../../../../../components/organisms/TreeTable/TreeViewStatus';

/**
 * @returns {React.ReactElement} return App bar and component
 */
const ViewStatusContent = () => {
  const data = [
    { id: 1, value: 'aaa' },
    { id: 2, value: 'bbb' }
  ];
  const { control } = useForm({});
  /**
   *@returns {any} --NULL
   */
  const onHandleChange = () => {
    return null;
  };

  return (
    <div>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={2}>
          <Controller
            name="entityType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDropdown
                label="Entity Type"
                data={data}
                value={value || ''}
                handleChange={onChange}
                name="entityType"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="entityName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDropdown
                label="Entity Name"
                data={data}
                value={value || ''}
                handleChange={onChange}
                name="entityName"
              />
            )}
          />
        </Grid>
        <Grid item xs={1.6}>
          <CustomDatePicker
            label="Effective From"
            onHandleChange={(e) => onHandleChange(e)}
          />
        </Grid>
        <Grid item xs={1.6}>
          <CustomDatePicker
            label="Effective to"
            onHandleChange={(e) => onHandleChange(e)}
          />
        </Grid>
        <Grid item xs={3.8}>
          <CustomTextField
            label="Reason"
            values="Duis porta, ligula rhoncus euismod pretium, nisi tellus"
          />
        </Grid>
        <Grid item xs={1}>
          <div className="Switch">Status</div>
          <div className="SwitchBar">
            <CustomSwitch
              name="Status"
              onChange={() => null}
              value=""
              switchColor="switch"
            />
          </div>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <Grid>
        <TreeViewStatus />
      </Grid>
    </div>
  );
};

export default ViewStatusContent;
