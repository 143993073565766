/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTextField from '../../atoms/TextInput';
import CustomDropdown from '../../atoms/CustomDropdown/Dropdown';
/**
 *
 * @param {object} props - required props in ComboText component
 * @returns {React.ReactElement} - returns the combo text component
 */
const ComboText = (props) => {
  const [description, setDescription] = useState('');
  const {
    textLable,
    dropDownLable,
    dropName,
    onChangeCombo,
    dropValue,
    dataList,
    dropBreak,
    textBreak,
    textDisable
  } = props;
  /**
   *
   * @param {*} e - passing event
   */
  const setDescValue = (e) => {
    const dataVal = e.target.value;
    dataList?.map((item) => {
      if (dataVal === item.id) {
        return setDescription(item.data);
      }
      return setDescription('');
    });
  };

  return (
    <Grid
      className="comboDiv"
      container
      item
      md={12}
      spacing={2}
      pt={0}
      mt={-3.5}
    >
      <Grid item md={dropBreak} pt={0}>
        <CustomDropdown
          label={dropDownLable}
          data={dataList}
          value={dropValue}
          handleChange={(e) => {
            onChangeCombo(e);
            setDescValue(e);
          }}
          name={dropName}
        />
      </Grid>
      <Grid item md={textBreak} pt={0}>
        <CustomTextField
          label={textLable}
          value={description}
          disabled={textDisable}
        />
      </Grid>
    </Grid>
  );
};

export default ComboText;
ComboText.propTypes = {
  textLable: PropTypes.string,
  dropDownLable: PropTypes.string,
  dropName: PropTypes.string,
  onChangeCombo: PropTypes.func,
  dropValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  dataList: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  dropBreak: PropTypes.number,
  textBreak: PropTypes.number,
  textDisable: PropTypes.bool
};
ComboText.defaultProps = {
  textLable: '',
  dropDownLable: '',
  dropName: '',
  onChangeCombo: () => null,
  dropBreak: 0,
  textBreak: 0,
  textDisable: 0
};
