import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepButton } from '@mui/material';
/**
 * @param {*} props -all props
 * @name CustomStepper
 * @returns {React.ReactElement} --Custom Functional component
 */
const CustomStepper = (props) => {
  const { activeStepper, stepperConfig, onClickLabel, isNonLinear } = props;

  return (
    <Stepper nonLinear={isNonLinear} activeStep={activeStepper}>
      {stepperConfig.map((item, index) => {
        const stepKey = index;
        const { label, styles } = item;
        return (
          <Step key={stepKey}>
            <StepButton color="inherit" onClick={() => onClickLabel(stepKey)}>
              <StepLabel sx={styles}>{label}</StepLabel>
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
};

CustomStepper.propTypes = {
  stepperConfig: PropTypes.arrayOf(PropTypes.objectOf),
  activeStepper: PropTypes.number.isRequired,
  onClickLabel: PropTypes.func,
  isNonLinear: PropTypes.bool
};

CustomStepper.defaultProps = {
  stepperConfig: [],
  onClickLabel: () => null,
  isNonLinear: false
};

export default CustomStepper;
