/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Divider, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import './Drawer.css';
import PropTypes from 'prop-types';
import customIcons from '../../../utils/icons/icons';
import Typography from '../../atoms/Typography/Typography';
import DrawerContent from './DrawerContent';
/**
 * @param {object} props - required to define props of drawer
 * @returns {React.ReactElement} return Custom Drawer
 */
const CustomDrawer = (props) => {
  const {
    openDrawer,
    onCloseDrawer,
    toggleDrawerDirection,
    onSubmitData,
    formId,
    defaultValues,
    isFormEdit,
    drawerName,
    additionalColumnsComponent,
    additionalColumn
  } = props;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues });
  useEffect(() => {
    if (isFormEdit) {
      reset(defaultValues);
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, isFormEdit]);

  /**
   *
   * @returns {React.ReactElement} - returns the drawer list
   */
  const list = () => (
    <Box
      sx={{
        width:
          toggleDrawerDirection === 'top' || toggleDrawerDirection === 'bottom'
            ? 'auto'
            : 350
      }}
      role="presentation"
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="drawerTitle"
      >
        <Grid>
          <Typography type="heading" text={`${drawerName}`} />
        </Grid>
        <Grid>
          <img
            src={customIcons.ModalClose}
            alt="close"
            className="closeModal"
            onClick={() => onCloseDrawer(false)}
            aria-hidden
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid className="drawerContent">
        <DrawerContent
          control={control}
          Controller={Controller}
          handleSubmit={handleSubmit}
          form={formId}
          errors={errors}
          onSubmit={(data) => onSubmitData(data)}
          drawerName={drawerName}
          additionalColumn={additionalColumn}
          additionalColumnsComponent={additionalColumnsComponent}
        />
      </Grid>
    </Box>
  );

  return (
    <div>
      {toggleDrawerDirection.map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            variant="persistent"
            anchor={anchor}
            open={openDrawer}
            onClose={() => onCloseDrawer(false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};
export default CustomDrawer;
CustomDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  onCloseDrawer: PropTypes.func,
  toggleDrawerDirection: PropTypes.arrayOf(PropTypes.string)
};
CustomDrawer.defaultProps = {
  openDrawer: false,
  onCloseDrawer: () => null,
  toggleDrawerDirection: []
};
