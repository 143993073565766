import MasterIcon from '../../assets/icons/masterIcon.svg';
import Plus from '../../assets/icons/plus.svg';
import Buildings from '../../assets/icons/buildings.svg';
import Content from '../../assets/icons/content.svg';
import Calender from '../../assets/icons/calendar.svg';
import CrossIcon from '../../assets/icons/CrossIcon.svg';
import ModalClose from '../../assets/icons/modalClose.svg';
import Edit from '../../assets/icons/iconEdit.svg';
import Delete from '../../assets/icons/delete.svg';
import BulkAdd from '../../assets/icons/bulkAdd.svg';
import Organization from '../../assets/icons/organization.svg';
import Checked from '../../assets/icons/checked.svg';
import Cross from '../../assets/icons/redCross.png';

const customIcons = {
  MasterIcon,
  Plus,
  Buildings,
  Content,
  Calender,
  CrossIcon,
  ModalClose,
  Edit,
  Delete,
  BulkAdd,
  Organization,
  Checked,
  Cross
};
export default customIcons;
