/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import './CriteriaLabel.css';
import { Grid } from '@mui/material';
import Delete from '../../../assets/icons/delete.svg';
import Add from '../../../assets/icons/Add.svg';
import Edit from '../../../assets/icons/iconEdit.svg';

/**
 *
 * @returns {React.ReactElement} - returns the CriteriaLabel component
 */
const CriteriaLabel = () => {
  const depreqdtls = [
    {
      depcriteria: 'To display deposit Requirement',
      deppercval: '80%'
    },
    {
      depcriteria: 'To display deposit Requirement',
      deppercval: '80%'
    },
    {
      depcriteria: 'To display deposit Requirement',
      deppercval: '80%'
    }
  ];

  /**
   *
   */
  return depreqdtls?.map((data, index) => {
    return (
      <Grid container md={12} className="Container1">
        <Grid container md={9}>
          <Grid md={0.3}>
            <div className="counter">{index + 1}</div>
          </Grid>
          <Grid md={0.5} className="CriteriaImg">
            <div>
              <img src={Add} alt="add" className="addImg" />
            </div>
          </Grid>
          <Grid md={4}>
            <div className="content-header">
              <div className="head">Criteria</div>
              <div className="content1">{data.depcriteria}</div>
            </div>
          </Grid>
          <Grid md={6}>
            <div className="content-header">
              <div className="head">Deposit Persentage</div>
              <div className="content1">{data.deppercval}</div>
            </div>
          </Grid>
        </Grid>
        <Grid container md={3}>
          <Grid md={7}></Grid>
          <Grid md={2} className="DelContainer">
            <div className="deleteBtn">
              <img src={Edit} alt="delete" />
            </div>
            <div className="deleteBtn">
              <img src={Delete} alt="delete" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  });
};
export default CriteriaLabel;
