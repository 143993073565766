/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'rcm-binder';
import StepperHeader from '../../../components/organisms/StepperHeader';
import Servicelevel from './Servicelevel';
import Chargedefinition from './ChargeDefinition';
import Toaster from '../../../components/atoms/Toaster/Toaster';
import initialValue from './defaultValues';
/**
 *
 * @returns {React.ReactElement} AddChargeMaster Page
 */
const AddChargeMaster = () => {
  const [activeStepper, setActiveStepper] = useState(0);
  const [successMsg, setSuccessMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [successType, setType] = useState('');
  const [defaultValues, setDefaultValues] = useState({});
  const [apiCall, setApiCall] = useState(false);
  const [apiData, setApiData] = useState({});
  const [ids, setIds] = useState('');
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('isEdit');
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({ defaultValues });
  const watchField = watch();
  const watchDateField = watch('aliasservmapping');
  const watchSwitchField = watch('isPackage');
  const watchEncounterField = watch('enctypesallowed');
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(defaultValues).length) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);
  const {
    chargeMaster_upsert,
    chargeMaster_update,
    chargeMaster_by_id,
    chargeMaster_read
  } = useSelector((state) => state?.chargeMasterSlice);
  let isError;
  useEffect(() => {
    if (apiCall) {
      if (chargeMaster_upsert?.data && (isEdit !== 'true' || undefined)) {
        if (chargeMaster_upsert?.data?.Code === 201) {
          reset(initialValue);
          setType('success');
          setMsg('Data added successfully');
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 5000);
        }
        if (chargeMaster_upsert?.data?.Code !== 201) {
          setType('error');
          setMsg('Something went wrong');
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 5000);
        }
      }
      if (chargeMaster_update?.data && (isEdit === 'true' || undefined)) {
        if (chargeMaster_update?.data?.Code === 201) {
          setType('success');
          setMsg('Data Updated successfully');
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 5000);
        }
        if (chargeMaster_update?.data?.Code !== 201) {
          setType('error');
          setMsg('Something went wrong');
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
          }, 5000);
        }
      }
    }
  }, [
    chargeMaster_upsert,
    reset,
    dispatch,
    apiCall,
    defaultValues,
    isEdit,
    chargeMaster_update?.data
  ]);
  useEffect(() => {
    if (isEdit === 'true') {
      dispatch(actions.CHARGE_MASTER_READ());
    }
  }, [isEdit, id, dispatch]);
  useEffect(() => {
    if (isEdit === 'true') {
      const filterArr = [];
      chargeMaster_read?.data?.map((item) => {
        return filterArr.push(item._id);
      });
      setIds(filterArr);
    }
  }, [isEdit, chargeMaster_read?.data]);
  useEffect(() => {
    if (isEdit !== 'true' || undefined) {
      setDefaultValues(initialValue);
    }
  }, [isEdit, setDefaultValues, defaultValues]);
  useEffect(() => {
    if (isEdit === 'true' && ids?.length) {
      dispatch(actions.CHARGE_MASTER_BY_ID(ids[id - 1]));
    }
  }, [id, ids, isEdit, dispatch]);
  /**
   *
   * @returns
   */
  const editResourceRequirement = useCallback((data) => {
    const resourceArr = [];
    if (data.length) {
      data?.map((item) => {
        return resourceArr.push({
          resourcerole: item.resourcerole,
          maxnoofresources: item.maxnoofresources.toString(),
          ismandatory: item.ismandatory
        });
      });
    }
    return resourceArr;
  }, []);

  /**
   *
   * @returns
   */
  const editAssociateCharge = useCallback((data) => {
    const associateArr = [];
    if (data.length) {
      data?.map((item) => {
        return associateArr.push({
          chargecode: item.chargecode,
          chargeqtyperord: item.chargeqtyperord.toString(),
          isreflex: item.isreflex
        });
      });
    }
    return associateArr;
  }, []);

  /**
   *
   * @returns
   */
  const editAliasMapping = useCallback((data) => {
    const mappingArr = [];
    if (data.length) {
      data?.map((item) => {
        return mappingArr.push({
          aliastypeind: item.aliastypeind,
          aliastypecode: item.aliastypecode,
          aliascode: item.aliascode,
          aliasshortdesc: item.aliasshortdesc,
          efffr: item.efffr?.toString(),
          effto: item.effto?.toString()
        });
      });
    }
    return mappingArr;
  }, []);

  const editEncountertypes = useCallback((data, type) => {
    let value = false;
    data?.map((dat) => {
      if (type === dat?.enctype) {
        value = true;
        return value;
      }
      return value;
    });
    return value;
  }, []);
  useEffect(() => {
    if (chargeMaster_by_id?.data[0]) {
      const EditDefaultValues = {
        chargecode: chargeMaster_by_id.data[0].chargecode,
        shortdesc: chargeMaster_by_id?.data[0].shortdesc,
        longdesc: chargeMaster_by_id?.data[0].longdesc,
        chargecodesetid: `${chargeMaster_by_id?.data[0].chargecodesetid}`,
        chargetype: chargeMaster_by_id?.data[0].chargetype,
        chargegrp: chargeMaster_by_id?.data[0].chargegrp,
        chargecatg: chargeMaster_by_id?.data[0].chargecatg,
        isuserrateentry: chargeMaster_by_id?.data[0].isuserrateentry,
        isuserdescentry: chargeMaster_by_id?.data[0].isuserdescentry,
        isdirectentry: chargeMaster_by_id?.data[0].isdirectentry,
        ismatlitem: chargeMaster_by_id?.data[0].ismatlitem,
        isnonpatservice: chargeMaster_by_id?.data[0].isnonpatservice,
        iscaptureperfdept: chargeMaster_by_id?.data[0].iscaptureperfdept,
        enctypesallowed: chargeMaster_by_id?.data[0].enctypesallowed,
        maxdurnuom: chargeMaster_by_id?.data[0].maxdurnuom,
        maxordqtyuom: chargeMaster_by_id?.data[0].maxordqtyuom,
        baseuom: chargeMaster_by_id?.data[0].baseuom,
        baseqty: chargeMaster_by_id?.data[0].baseqty,
        maxordqtyval: chargeMaster_by_id?.data[0].maxordqtyval,
        maxdurnval: chargeMaster_by_id?.data[0].maxdurnval,
        maxtxnqty: chargeMaster_by_id?.data[0].maxtxnqty,
        relchargebasis: chargeMaster_by_id?.data[0].relchargebasis,
        requiredresources: chargeMaster_by_id?.data[0].requiredresources,
        assoccharges: chargeMaster_by_id?.data[0].assoccharges,
        chargesynonyms: chargeMaster_by_id?.data[0].chargesynonyms,
        aliasservmapping: chargeMaster_by_id?.data[0].aliasservmapping,
        pkgpricetype: chargeMaster_by_id?.data[0].pkgpricetype,
        pkgvaliddays: chargeMaster_by_id?.data[0].pkgvaliddays,
        pkgexplnote: chargeMaster_by_id?.data[0].pkgexplnote,
        deppercval: chargeMaster_by_id?.data[0].depreqdtls[0].deppercval,
        depositRequirement: [{}],
        packageDetails: [{}],
        fromday: chargeMaster_by_id?.data[0].packagedetails[0].fromday,
        today: chargeMaster_by_id?.data[0].packagedetails[0].today,
        enctype: chargeMaster_by_id?.data[0].packagedetails[0].enctype,
        israiseorder:
          chargeMaster_by_id?.data[0].packagedetails[0].israiseorder,
        qtylimit: chargeMaster_by_id?.data[0].packagedetails[0].qtylimit,
        amtlimit: chargeMaster_by_id?.data[0].packagedetails[0].amtlimit,
        limittype: chargeMaster_by_id?.data[0].packagedetails[0].limittype,
        isrefundable:
          chargeMaster_by_id?.data[0].packagedetails[0].isrefundable,
        ordercatalogcode:
          chargeMaster_by_id?.data[0].packagedetails[0].ordercatalogcode,
        pkgchargecode:
          chargeMaster_by_id?.data[0].packagedetails[0].pkgchargecode
      };
      EditDefaultValues.requiredresources = editResourceRequirement(
        chargeMaster_by_id?.data[0].requiredresources
      );
      EditDefaultValues.assoccharges = editAssociateCharge(
        chargeMaster_by_id?.data[0].assoccharges
      );
      EditDefaultValues.aliasservmapping = editAliasMapping(
        chargeMaster_by_id?.data[0].aliasservmapping
      );
      EditDefaultValues.ER = editEncountertypes(
        chargeMaster_by_id?.data[0].enctypesallowed,
        'ER'
      );
      EditDefaultValues.DC = editEncountertypes(
        chargeMaster_by_id?.data[0].enctypesallowed,
        'DC'
      );

      EditDefaultValues.EX = editEncountertypes(
        chargeMaster_by_id?.data[0].enctypesallowed,
        'EX'
      );
      EditDefaultValues.IP = editEncountertypes(
        chargeMaster_by_id?.data[0].enctypesallowed,
        'IP'
      );
      EditDefaultValues.OP = editEncountertypes(
        chargeMaster_by_id?.data[0].enctypesallowed,
        'OP'
      );
      setDefaultValues(EditDefaultValues);
    }
  }, [
    editAliasMapping,
    editResourceRequirement,
    chargeMaster_by_id,
    chargeMaster_update,
    editAssociateCharge,
    editEncountertypes
  ]);

  /**
   *
   * @param {string} name --Error message or name
   */
  const setError = useCallback((name) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isError = true;
    setType('error');
    setMsg(name);
    setSuccessMsg(true);
    setTimeout(() => {
      setSuccessMsg(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (apiCall && isEdit !== 'true') {
      dispatch(actions.CHARGE_MASTER_UPSERT(apiData));
    }
    if (apiCall && isEdit === 'true') {
      dispatch(actions.CHARGE_MASTER_UPDATE(apiData));
    }
  }, [apiCall, apiData, dispatch, isEdit]);
  /**
   *
   * @param {Array} data - passing data
   * @returns {Function} - returns relative value unit tab value
   */
  const getRelativeBase = (data) => {
    const realtiveArr = [];
    if (data.length) {
      data?.map((item) => {
        return realtiveArr.push({
          rvucode: item.rvucode,
          factor: parseInt(item.factor, 10)
        });
      });
    }
    return realtiveArr;
  };
  /**
   *
   * @param {Array} data - passing data
   * @returns {Function} - resource requirement tab values
   */
  const getResourceRequirement = useCallback(
    (data) => {
      const resourceArr = [];
      if (data.length) {
        data?.map((item) => {
          if (item.resourcerole.length && item.maxnoofresources.length) {
            return resourceArr.push({
              resourcerole: item.resourcerole,
              maxnoofresources: parseInt(item.maxnoofresources, 10),
              ismandatory: item.ismandatory
            });
          }
          return setError('Resource Requirement fields are required');
        });
      }
      return resourceArr;
    },
    [setError]
  );

  /**
   *
   * @param {Array} data - passing data
   * @returns {Function} - returns the associate charges tab value
   */
  const getAssociateCharge = useCallback(
    (prevData, currData) => {
      const associateArr = [];
      prevData?.map((item) => {
        if (item.resourcerole.length && item.maxnoofresources.length) {
          isError = false;
          return isError;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isError = true;
        return isError;
      });
      if (isError === false) {
        if (currData.length) {
          currData?.map((item) => {
            if (item.chargecode.length && item.chargeqtyperord.length) {
              return associateArr.push({
                chargecode: item.chargecode,
                chargeqtyperord: parseInt(item.chargeqtyperord, 10),
                isreflex: item.isreflex
              });
            }
            return setError('Associated Charges fields are required');
          });
        }
      }
      return associateArr;
    },
    [setError]
  );
  /**
   *
   * @param {Array}prevData -- Prev array data validation
   * @param {Array} currData -- set respective array object for API json structure
   * @returns {Function} -  returns the chargesynonyms tab values
   */
  const getSynonyms = (prevData, currData) => {
    const synonmysArr = [];
    prevData?.map((item) => {
      if (item.chargecode.length && item.chargeqtyperord.length) {
        isError = false;
        return isError;
      }
      isError = true;
      return isError;
    });
    if (isError === false) {
      if (currData.length) {
        currData?.map((item) => {
          if (
            item.synonymtype.length &&
            item.synonymtype.length
            // item.shortdesc.length &&
            // item.longdesc.length
          ) {
            return synonmysArr.push({
              synonymtype: item.synonymtype,
              langid: item.langid
              // shortdesc: item.shortdesc,
              // longdesc: item.longdesc
            });
          }
          return setError('Synonyms fields are required');
        });
      }
    }
    return synonmysArr;
  };
  /**
   *
   * @param  {Array}prevData --
   * @param  {Array} currData --
   * @returns {Array} -- structured array for json structure
   */
  const getAliasMapping = (prevData, currData) => {
    const mappingArr = [];
    prevData?.map((item) => {
      if (
        item.synonymtype.length &&
        item.synonymtype.length
        // item.shortdesc.length &&
        // item.longdesc.length
      ) {
        isError = false;
        return isError;
      }
      isError = true;
      return isError;
    });
    if (isError === false) {
      if (currData.length) {
        currData?.map((item) => {
          if (
            item.aliastypeind.length &&
            item.aliastypecode.length &&
            item.aliascode.length &&
            item.aliasshortdesc.length
          ) {
            return mappingArr.push({
              aliastypeind: item.aliastypeind,
              aliastypecode: item.aliastypecode,
              aliascode: item.aliascode,
              aliasshortdesc: item.aliasshortdesc,
              // efffr: Date.parse(item.efffr),
              // effto: Date.parse(item.effto)
              efffr: parseInt(item.efffr, 10),
              effto: parseInt(item.effto, 10)
              // efffr: new Date(item.efffr),
              // effto: new Date(item.effto)
            });
          }
          return setError('Alias Code Mapping fields are required');
        });
      }
    }
    return mappingArr;
  };

  /**
   *
   * @param {*} data -- onSubmit dat
   * @returns {Array} --
   */
  const enctypesallowedMapping = (data) => {
    const tmpEnctypesallowed = [];
    Object.entries(data).map(([key, value]) => {
      if (key === 'ER' && value) {
        return tmpEnctypesallowed.push({
          enctype: 'ER'
        });
      }
      if (key === 'DC' && value) {
        return tmpEnctypesallowed.push({
          enctype: 'DC'
        });
      }
      if (key === 'IP' && value) {
        return tmpEnctypesallowed.push({
          enctype: 'IP'
        });
      }
      if (key === 'EX' && value) {
        return tmpEnctypesallowed.push({
          enctype: 'EX'
        });
      }
      if (key === 'OP' && value) {
        return tmpEnctypesallowed.push({
          enctype: 'OP'
        });
      }
      return tmpEnctypesallowed;
    });
    return tmpEnctypesallowed;
  };

  /**
   *
   * @param {Array} data -- Value to check
   */
  const checkLastArrayOfObject = (data) => {
    data?.map((item) => {
      if (
        item.aliastypeind.length &&
        item.aliastypecode.length &&
        item.aliascode.length &&
        item.aliasshortdesc.length
      ) {
        return setApiCall(true);
      }
      return setApiCall(false);
    });
  };

  /**
   *
   * @param {*} data - passing data
   */
  const onsubmitData = async (data) => {
    if (!data.DC && !data.ER && !data.EX && !data.IP && !data.OP) {
      setType('error');
      setMsg('At least One Encounter Type should be selected');
      setSuccessMsg(true);
      setTimeout(() => {
        setSuccessMsg(false);
      }, 5000);
    } else {
      const finalData = { ...data };
      finalData.relchargebasis = getRelativeBase(data.relchargebasis);
      finalData.requiredresources = getResourceRequirement(
        data.requiredresources
      );
      finalData.assoccharges = getAssociateCharge(
        data.requiredresources,
        data.assoccharges
      );
      finalData.chargesynonyms = getSynonyms(
        data.assoccharges,
        data.chargesynonyms
      );
      finalData.aliasservmapping = getAliasMapping(
        data.chargesynonyms,
        data.aliasservmapping
      );
      finalData.enctypesallowed = enctypesallowedMapping(data);
      finalData.depreqdtls = [
        {
          depcriteria: [],
          deppercval: parseInt(data.deppercval, 10)
        }
      ];
      finalData.baseqty = parseInt(data.baseqty, 10);
      finalData.chargecode = data.chargecode;
      finalData.maxtxnqty = parseInt(data.maxtxnqty, 10);
      finalData.maxordqtyval = parseInt(data.maxordqtyval, 10);
      finalData.maxdurnval = parseInt(data.maxdurnval, 10);
      finalData.isuserrateentry = data.isuserrateentry || false;
      finalData.isuserdescentry = data.isuserdescentry || false;
      finalData.isdirectentry = data.isdirectentry || false;
      finalData.ismatlitem = data.ismatlitem || false;
      finalData.isnonpatservice = data.isnonpatservice || false;
      finalData.iscaptureperfdept = data.iscaptureperfdept || false;
      // delete finalData.deppercval;
      finalData.ispackage = true;
      finalData.pkgpricetype = data.pkgpricetype;
      finalData.pkgvaliddays = parseInt(data.pkgvaliddays, 10);
      finalData.pkgexplnote = data.pkgexplnote;
      finalData.packagedetails = [
        {
          _id: 'string',
          pkgchargecode: data.pkgchargecode,
          enctype: data.enctype,
          fromday: parseInt(data.fromday, 10),
          today: parseInt(data.today, 10),
          chargecode: 'string',
          pkgserviceinclexclcriteria: [],
          ordercatalogcode: data.ordercatalogcode,
          israiseorder: data.israiseorder || false,
          discperc: 1,
          qtylimit: parseInt(data.qtylimit, 10),
          amtlimit: parseInt(data.amtlimit, 10),
          limittype: data.limittype,
          isrefundable: data.isrefundable || false,
          SourceSystemID: 'string',
          SourceSystemRefId: 'string'
        }
      ];
      delete finalData.OP;
      delete finalData.EX;
      delete finalData.IP;
      delete finalData.DC;
      delete finalData.ER;
      // delete finalData.depositRequirement;
      delete finalData.undefined;
      delete finalData.isPackage;
      delete finalData.isPackage;
      delete finalData.packageDetails;
      delete finalData.depositRequirement;
      delete finalData.synonmys;
      delete finalData.aliasCodeMap;
      delete finalData.isPackage;
      delete finalData.isPackage;
      delete finalData.packageDetails;
      delete finalData.deppercval;
      delete finalData.enctype;
      delete finalData.fromday;
      delete finalData.factor;
      delete finalData.today;
      // delete finalData.chargecode;
      delete finalData.israiseorder;
      delete finalData.qtylimit;
      delete finalData.amtlimit;
      delete finalData.limittype;
      delete finalData.isrefundable;
      delete finalData.ordercatalogcode;
      delete finalData.pkgchargecode;
      checkLastArrayOfObject(finalData.aliasservmapping);
      if (isEdit === 'true') {
        finalData._id = ids[id - 1];
      }
      setApiData(finalData);
    }
  };
  const stepperContent = [
    <Chargedefinition
      control={control}
      handleSubmit={handleSubmit}
      onSubmit={(d) => onsubmitData(d)}
      errors={errors}
      defaultValues={defaultValues}
      watchField={watchField}
      watchDateField={watchDateField}
      watchSwitchField={watchSwitchField}
      watchEncounterField={watchEncounterField}
    />,
    <Servicelevel control={control} />
  ];
  return (
    <>
      {successMsg && (
        <Toaster
          type={successType}
          open={successMsg}
          text={msg}
          handleClose={() => null}
        />
      )}
      <StepperHeader
        activeStepper={activeStepper}
        handleSubmit={handleSubmit}
        onBackHandler={() => setActiveStepper(0)}
        onSave={() => {
          setActiveStepper(activeStepper + 1);
        }}
        onPreviousHandler={() => setActiveStepper(activeStepper - 1)}
        onCancel={() => setActiveStepper(0, navigate('/ChargeMaster'))}
        onClickLabel={(val) => setActiveStepper(val)}
      />
      {stepperContent.map((item, index) => {
        const key = index;
        return <Box key={key}>{activeStepper === key && item}</Box>;
      })}
    </>
  );
};

export default AddChargeMaster;
