import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Grid } from '@mui/material';
import AppBarHeader from '../../components/organisms/AppBar';
import CustomNavbar from '../../components/organisms/SideNav';
import Logout from '../Logout';
import ChargeMaster from '../ChargeMaster';
import Addchargemaster from '../ChargeMaster/AddChargeMaster';
import ChargeCodeSet from '../GeneralMaster/ChargeCodeSet';
import './main.css';

/**
 * @returns {React.ReactElement} return App bar and component
 */
const MainPage = () => {
  return (
    <>
      <AppBarHeader />
      <Grid container item xs={12} className="mainpage">
        <Grid item xs={0.5}>
          <CustomNavbar />
        </Grid>
        <Grid item xs={11.5}>
          <Routes className="bgs">
            <Route path="/logout" element={<Logout />} />

            <Route
              path="/GeneralMaster/addchargemaster"
              element={<Addchargemaster />}
            />
            <Route
              path="/ChargeMaster/addchargemaster"
              element={<Addchargemaster />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="/GeneralMaster" element={<ChargeCodeSet />} exact />
            <Route path="/ChargeMaster" element={<ChargeMaster />} />
          </Routes>
        </Grid>
      </Grid>
    </>
  );
};

export default MainPage;
