import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import Typography from '../../atoms/Typography/Typography';
import Icon from '../../../assets/icons/icons8-window-settings.svg';
import './SideNav.css';

/**
 * @param {*} props - props
 * @param {any} props.item - object
 * @returns {React.ReactElement} return the side Menu list Component
 */
const SingleLevel = (props) => {
  const { item } = props;
  return (
    <ListItem button component={Link} to={item.path} className="activeColor">
      <ListItemIcon>
        <img src={item.icon} alt="" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            text={item.title.DisplayText.Text}
            colorType="quaternary"
            customStyle={item.title.Typography}
          />
        }
      />
    </ListItem>
  );
};
SingleLevel.propTypes = {
  item: PropTypes.shape()
};
SingleLevel.defaultProps = {
  item: {}
};

/**
 * @param {any} data - object
 * @returns {React.ReactElement} return children
 */
const hasChildren = (data) => {
  const { items: children } = data;
  if (children === undefined) {
    return false;
  }
  if (children.constructor !== Array) {
    return false;
  }
  if (children.length === 0) {
    return false;
  }
  return true;
};

/**
 * @param {*} props - props
 * @param {any} props.item - object
 * @param {boolean} props.closeMenu - close Menu
 * @returns {React.ReactElement} return Sub side Nav Component
 */
const MultiLevel = (props) => {
  const { item, closeMenu, selectKey } = props;
  const { items: children } = item;
  const [open, setOpen] = useState(closeMenu);
  const [subOpen, setSubOpen] = useState(closeMenu);
  const [getKey, setGetKey] = useState();
  const [mainId, setMainId] = useState('');

  useEffect(() => {
    if (!closeMenu) {
      setOpen(closeMenu);
      setSubOpen(closeMenu);
      setMainId('');
      setGetKey();
    }
  }, [closeMenu]);
  /**
   * @param {string} data - data
   */
  const handleClick = (data) => {
    setOpen((prev) => !prev);
    setMainId(data);
  };

  /**
   * @param {boolean} data -
   */
  const handleSubClick = (data) => {
    setSubOpen((prev) => !prev);
    setGetKey(data);
  };

  return (
    <div>
      <ListItem
        button
        onClick={() => handleClick(item.title.DisplayText.Text)}
        className={`${
          selectKey === mainId && open && 'activeList'
        } activeColor`}
      >
        <ListItemIcon>
          <img src={Icon} alt="" />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              text={item.title.DisplayText.Text}
              colorType="quaternary"
              customStyle={item.title.Typography}
            />
          }
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={open}
        style={{ minHeight: 'none' }}
        timeout="auto"
        className=""
      >
        <List component="div" disablePadding>
          {children.map((child, index) => (
            <div>
              {child.items !== undefined ? (
                <div>
                  <ListItem
                    button
                    // component={Link}
                    to={item.items[index].path}
                    onClick={() => handleSubClick(index)}
                    className={`${
                      getKey === index && 'activeSubList'
                    } activeColor`}
                  >
                    <ListItemIcon>
                      <img src={item.items[index].icon} alt="" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          text={item.items[index].title.DisplayText.Text}
                          colorType="quaternary"
                          customStyle={item.items[index].title.Typography}
                        />
                      }
                    />
                    {subOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                  <Collapse
                    in={index === getKey ? subOpen : false}
                    style={{ minHeight: 'none' }}
                    timeout="auto"
                    className=""
                  >
                    <List component="div" disablePadding>
                      {child.items.map((childs) => {
                        return hasChildren(childs) ? (
                          <MultiLevel item={childs} />
                        ) : (
                          <SingleLevel item={childs} />
                        );
                      })}
                    </List>
                  </Collapse>
                </div>
              ) : (
                <div>
                  {hasChildren(child) ? (
                    <MultiLevel item={child} />
                  ) : (
                    <SingleLevel item={child} />
                  )}
                </div>
              )}
            </div>
          ))}
        </List>
      </Collapse>
    </div>
  );
};
MultiLevel.propTypes = {
  item: PropTypes.shape(),
  closeMenu: PropTypes.bool,
  selectKey: PropTypes.string
};
MultiLevel.defaultProps = {
  item: {},
  closeMenu: '',
  selectKey: ''
};

/**
 * @name CustomTextField
 * @param {object} props - required props in Side Menu component
 * @returns {React.ReactElement} return the Side Menu Component
 */
const SiderList = (props) => {
  const { menu, closeMenu } = props;

  return (
    menu &&
    menu?.map((item, index) => {
      const key = { index };
      return (
        <div key={key}>
          {hasChildren(item) ? (
            <div>
              <MultiLevel
                item={item}
                selectKey={item.title.DisplayText.Text}
                closeMenu={closeMenu}
              />
            </div>
          ) : (
            <SingleLevel item={item} />
          )}
        </div>
      );
    })
  );
};
export default SiderList;
SiderList.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.objectOf),
  closeMenu: PropTypes.bool
};
SiderList.defaultProps = {
  menu: '',
  closeMenu: ''
};
