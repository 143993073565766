import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'rcm-binder';
import EnhancedTable from '../../components/organisms/SortTable/sortTable';
import Headers from './cmTableHeader';
import './chargemaster.css';

/**
 * @returns {React.ReactElement} returns charge master page (Functional Component)
 */
const Chargemaster = () => {
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    dispatch(actions.CHARGE_MASTER_READ());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);
  const { chargeMaster_read } = useSelector(
    (state) => state?.chargeMasterSlice
  );

  useEffect(() => {
    const filterArr = [];
    chargeMaster_read?.data?.map((item, i) => {
      const id = i + 1;
      return filterArr.push({
        id,
        Code: `${item.chargecode}`,
        Description: item.shortdesc,
        CodeSet: item.chargecodesetid,
        Category: item.chargecatg,
        Type: item.chargetype,
        Group: item.chargegrp,
        Status: item.activestatus,
        edit: ''
      });
    });
    setRowData(filterArr);
  }, [chargeMaster_read?.data]);
  return (
    <div className="maincustomGrid">
      <EnhancedTable
        headCells={Headers}
        rowData={rowData}
        tableId="396156885"
      />
    </div>
  );
};
export default Chargemaster;
