const Entries = [
  {
    isTextField: true,
    label: 'Column Name',
    name: 'columnname',
    breakpoint: 5
  },
  {
    isDropdown: true,
    label: 'Column Type',
    name: 'columntype',
    data: [
      { id: 1, value: 'aaa' },
      { id: 2, value: 'bbb' }
    ],
    breakpoint: 2.5
  },
  {
    isTextField: true,
    label: 'Column Size',
    name: 'columnsize',
    breakpoint: 2.5
  },
  {
    isCheckBox: true,
    label: 'Mandatory',
    name: 'ismandatory',
    breakpoint: 1.5
  },
  {
    isDelete: true,
    breakpoint: 0.5
  }
];

export default Entries;
