import React from 'react';
import PropTypes from 'prop-types';
import './textInput.css';
import { Box } from '@mui/material';
import Typography from '../Typography/Typography';
/**
 * @name CustomTextField
 * @param {object} props - required props in text field component
 * @returns {React.ReactElement} return the TextField Component
 */
const CustomTextField = (props) => {
  const {
    label,
    onChange,
    type,
    value,
    disabled,
    error,
    errorMsg,
    customClass,
    requiredField,
    maxLength,
    min,
    id
  } = props;

  return (
    <Box>
      <Box>
        <Typography
          type="caption"
          text={label}
          customClass="labelText"
          colorType="senary"
          requiredField={requiredField}
        />
      </Box>
      <Box>
        <input
          id={id}
          type={type}
          value={value}
          error={error}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          className={`${error && 'errorBox'} inputBox  ${customClass} ${
            disabled && 'disable'
          }`}
          errorMsg={errorMsg}
          maxLength={maxLength}
          min={min}
        />
        {error && <div className="errorBo">{errorMsg}</div>}
      </Box>
    </Box>
  );
};
export default CustomTextField;
CustomTextField.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  errorMsg: PropTypes.string,
  customClass: PropTypes.string,
  requiredField: PropTypes.bool,
  min: PropTypes.number,
  id: PropTypes.string
};
CustomTextField.defaultProps = {
  onChange: () => {},
  type: 'text',
  disabled: false,
  error: false,
  label: '',
  maxLength: '',
  errorMsg: '',
  customClass: '',
  value: '',
  requiredField: false,
  min: 0,
  id: ''
};
