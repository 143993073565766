import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import axios from 'axios';
import CustomStepper from '../../atoms/CustomStepper/customStepper';
import CustomButton from '../../atoms/CustomButton/Button';
import './stepper.css';
/**
 * @param {*} props -all props
 * @name StepperHeader
 * @returns {React.ReactElement} --Custom Functional component
 */
const StepperHeader = (props) => {
  const {
    activeStepper,
    onCancel,
    onPreviousHandler,
    onSave,
    onBackHandler,
    onClickLabel
  } = props;

  const [pageHeaderContent, setPageHeaderContent] = useState({});

  const { title, stepper, buttons } = pageHeaderContent;
  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    axios({
      method: 'post',
      url: `${url}/read_documents`,
      data: {
        db_name: 'RCM',
        entity: 'pageHeader',
        return_fields: 'pageHeader'
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const childProps = response?.data.result[0].children;
      setPageHeaderContent(childProps);
    });
  }, [url]);

  /**
   * @name getStepperConfig return arr for stepper config
   * @returns {Array} - stepper content
   */
  const getStepperConfig = () => {
    const tempArr = [];
    if (stepper.config && stepper.config.length > 1) {
      stepper.config.map((item) => {
        return tempArr.push({
          label: item.DisplayText.Text,
          path: item.DisplayText.path,
          icon: item.icon.active ? item.icon.url : '',
          styles: item.styles
        });
      });
    }
    return tempArr;
  };
  return (
    <Paper sx={{ p: 1 }} className="paper">
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs={9} flexDirection="row" alignItems="center">
          <Grid
            item
            xs={3.5}
            container
            flexDirection="row"
            alignItems="center"
            sx={{ pl: 3 }}
          >
            <IconButton
              onClick={onBackHandler}
              color="secondary"
              sx={{ backgroundColor: '#F3F4F7' }}
              className="iconbtn"
            >
              <ArrowBackIosNewIcon className="arrowicon" color="action" />
            </IconButton>
            <Typography
              variant={title?.Typography.variant}
              component={title?.Typography.component}
              sx={title?.styles}
              className="customstyle"
            >
              {title?.DisplayText.Text}
            </Typography>
          </Grid>
          {stepper?.active && (
            <Divider
              orientation="vertical"
              variant="middle"
              light
              flexItem
              sx={{ border: '0.5px solid #DEE5EC', mr: 4, height: '20px' }}
            />
          )}
          <Grid item xs={6}>
            {stepper?.active && (
              <CustomStepper
                stepperConfig={getStepperConfig()}
                activeStepper={activeStepper}
                onClickLabel={onClickLabel}
                isNonLinear={stepper?.isNonLinear}
              />
            )}
          </Grid>
        </Grid>
        {buttons?.active && (
          <Grid
            className="btnGrid"
            item
            xs={stepper?.config && stepper?.config.length > 1 ? 3 : 2}
          >
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              // justifyContent={
              //   stepper?.config && stepper?.config.length > 1
              //     ? 'space-around'
              //     : 'space-evenly'
              // }
            >
              {buttons?.cancelBtn.active && (
                <Button
                  variant={buttons?.cancelBtn.variant}
                  color={buttons?.cancelBtn.color}
                  onClick={onCancel}
                  size="small"
                  className="cancelbtn"
                >
                  {buttons?.cancelBtn.DisplayText.Text}
                </Button>
              )}
              {stepper?.config && stepper?.config.length > 1 ? (
                <>
                  {activeStepper !== 0 && buttons?.previousBtn.active && (
                    <Button
                      variant="contained"
                      color="disable"
                      onClick={onPreviousHandler}
                      className="savebtn"
                      disableRipple
                      disableFocusRipple
                    >
                      {buttons?.previousBtn.DisplayText.Text}
                    </Button>
                  )}
                  {buttons?.SaveAndProceedBtn.active && (
                    // <Button
                    //   variant="contained"
                    //   color={activeStepper !== 0 ? 'secondary' : 'info'}
                    //   onClick={onSave}
                    //   className="savebtn"
                    // >
                    //   {activeStepper === 0
                    //     ? buttons?.SaveAndProceedBtn.DisplayText.Text
                    //     : 'Save'}
                    // </Button>
                    <CustomButton
                      id="ChargeMaster"
                      formBtn
                      onClickHandle={onSave}
                      btnTitle={
                        activeStepper === 0
                          ? buttons?.SaveAndProceedBtn.DisplayText.Text
                          : 'Save'
                      }
                    />
                  )}
                </>
              ) : (
                buttons?.SaveBtn.active && (
                  <Button
                    className="savebtn"
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                  >
                    {buttons?.SaveBtn.DisplayText.Text}
                  </Button>
                )
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

StepperHeader.propTypes = {
  activeStepper: PropTypes.number,
  onCancel: PropTypes.func,
  onPreviousHandler: PropTypes.func,
  onSave: PropTypes.func,
  onClickLabel: PropTypes.func,
  onBackHandler: PropTypes.func
};

StepperHeader.defaultProps = {
  activeStepper: 0,
  onCancel: () => null,
  onPreviousHandler: () => null,
  onSave: () => null,
  onClickLabel: () => null,
  onBackHandler: () => null
};

export default StepperHeader;
