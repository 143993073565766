import React from 'react';
import './Label.css';
import PropTypes from 'prop-types';
import Add from '../../../assets/icons/Add.svg';
/**
 *
 * @param {object} props - required props in Label component
 * @returns {React.ReactElement} - returns the Label component
 */
const Label = (props) => {
  const { value } = props;
  return (
    <div className="labelContainer">
      <div className="img">
        <img src={Add} alt="Italian Trulli" className="imageHolder" />
      </div>
      <div className="content">{value}</div>
    </div>
  );
};
export default Label;
Label.propTypes = {
  value: PropTypes.string
};
Label.defaultProps = {
  value: ''
};
