import React from 'react';
// import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import Label from '../../atoms/CustomLabel/Label';
import PersentageInput from '../../atoms/DepositPersentageInput/Persentage';

import './Requirement.css';
import CustomButton from '../../atoms/CustomButton/Button';
import CustomTextField from '../../atoms/TextInput';
import CustomDropdown from '../../atoms/CustomDropdown/Dropdown';
/**
 *
 * @param {object} props - required props in DepositRequirement
 * @returns {React.ReactElement} - returns the DepositRequirement component
 */
const DepositRequirement = (props) => {
  const { values, handleBtn, handleInput, control, Controller, addNewControl } =
    props;
  /**
   *
   */
  const onClickHandle1 = () => {
    handleBtn();
  };
  /**
   *
   */
  const onClickInputHandle = () => {
    handleInput();
  };
  return (
    <div className="requirementContainer">
      <div className="MainContainer">
        <div className="container1">
          <div>
            <a
              className="label"
              href="https://rulebuilder.dev.ainqaplatform.in/rules?token=68b4e72f-d774-42d1-927c-4ac81d2adedc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Label value={values} className="Add" />
            </a>
          </div>
          <div className="condition">(or)</div>
          <div>
            <a
              className="label"
              href="https://rulebuilder.dev.ainqaplatform.in/rules?token=68b4e72f-d774-42d1-927c-4ac81d2adedc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Label value="Add Existing Rule Set" className="Add1" />
            </a>
          </div>
        </div>
        {!addNewControl && (
          <div className="input1">
            <PersentageInput
              Controller={Controller}
              control={control}
              placeholder="Enter Deposit Persentage"
              Category="%"
              label="Deposit Persentage"
            />
          </div>
        )}
        {addNewControl && (
          <div className="inputContaainer">
            <div className="inputPackage">
              <Controller
                // key={field.id}
                // rules={{
                //   required: keyValue?.isRequired,
                //   min: keyValue.min,
                //   pattern: keyValue.pattern,
                //   maxLength: keyValue.maxLength
                // }}
                name="qtylimit"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomTextField
                    label="Quantity Limit"
                    type="number"
                    onChange={(e) => onChange(e)}
                    value={value}
                    // maxLength={keyValue.maxLength}
                    // max={keyValue.max}
                    // id={keyValue.id}
                    // min={keyValue.min}
                  />
                )}
              />
            </div>
            <div className="inputPackage">
              <Controller
                // key={field.id}
                // rules={{
                //   required: keyValue?.isRequired,
                //   min: keyValue.min,
                //   pattern: keyValue.pattern,
                //   maxLength: keyValue.maxLength
                // }}
                name="amtlimit"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomTextField
                    label="Amount Limit"
                    type="number"
                    onChange={(e) => onChange(e)}
                    value={value}
                    // maxLength={keyValue.maxLength}
                    // max={keyValue.max}
                    // id={keyValue.id}
                    // min={keyValue.min}
                  />
                )}
              />
            </div>
            <div className="DropPackage">
              <Controller
                // key={field.id}
                name="limittype"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomDropdown
                    data={[
                      { id: '1', value: 'aaa' },
                      { id: '2', value: 'bbb' }
                    ]}
                    label="Limit Type"
                    value={value}
                    handleChange={onChange}
                    name="userGroup"
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className="Btn">
          <div className="save">
            <CustomButton
              type="differentiation_background"
              btnTitle="Save & Add anorther"
              TitleColor="quinary"
              onClickHandle={onClickHandle1}
            />
          </div>
          <div className="adds">
            <CustomButton btnTitle="Add" onClickHandle={onClickInputHandle} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DepositRequirement;
DepositRequirement.propTypes = {
  values: PropTypes.string,
  handleBtn: PropTypes.func,
  handleInput: PropTypes.func,
  control: PropTypes.string,
  Controller: PropTypes.string,
  addNewControl: PropTypes.string
};
DepositRequirement.defaultProps = {
  values: '',
  handleBtn: () => null,
  handleInput: () => null,
  control: '',
  Controller: '',
  addNewControl: ''
};
