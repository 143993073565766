/* eslint-disable react/prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '@mui/lab/DatePicker';
import TextField from '@mui/material/TextField';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Typography from '../Typography/Typography';
import './picker.css';

/**
 * @name CustomDatePicker
 * @param {*} props -- required props
 * @returns {React.ReactElement} --
 */
const CustomDatePicker = (props) => {
  const {
    onHandleChange,
    value,
    label,
    error,
    errorMsg,
    disabled,
    required,
    minDate,
    maxDate
  } = props;

  return (
    <>
      <Typography
        type="caption"
        text={`${label} ${required && '*'}`}
        customClass="labelText"
        colorType="senary"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          minDate={minDate && new Date(minDate)}
          maxDate={maxDate && new Date(maxDate)}
          value={value}
          className="datePick"
          onChange={onHandleChange}
          inputFormat="dd-MM-yyyy"
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              size="small"
              label=""
              placeholder="Placeholder"
              className={`${disabled && 'disabled'} dateTextField`}
              helperText={error && errorMsg}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
export default CustomDatePicker;
CustomDatePicker.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.string,
  label: PropTypes.string,
  errorMsg: PropTypes.string
};
CustomDatePicker.defaultProps = {
  label: '',
  value: new Date(),
  error: false,
  required: '',
  errorMsg: '',
  disabled: false
};
