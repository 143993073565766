import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import './card.css';

/**
 *
 * @param  {object} props - required props in card component
 * @returns {React.ReactElement} - returns the card component
 */
const Card = (props) => {
  const {
    children,
    cardTitleType,
    cardTitle,
    optionalHeader,
    toolTip,
    customClass
  } = props;
  return (
    <div className={`${toolTip && 'tooltipHold'} ${customClass} mainLayout`}>
      <Grid container>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <Typography
              type={cardTitleType}
              text={cardTitle}
              colorType="primary"
              customClass={`${toolTip && 'tooltipHead'} cardTitle`}
            />
          </Grid>
          <Grid className="optionalhead" item>
            {optionalHeader}
          </Grid>
        </Grid>

        {children}
      </Grid>
    </div>
  );
};

export default Card;

Card.propTypes = {
  children: PropTypes.node,
  optionalHeader: PropTypes.node,
  cardTitleType: PropTypes.string,
  cardTitle: PropTypes.string,
  customClass: PropTypes.string,
  toolTip: PropTypes.bool
};
Card.defaultProps = {
  children: <> </>,
  optionalHeader: <> </>,
  cardTitleType: '',
  cardTitle: '',
  customClass: '',
  toolTip: false
};
