import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableContainer } from '@mui/material';
import TableHeader from './TableHeader';
import TableBodySection from './TableBody';
import './stickyStyles.css';

/**
 *
 * @param {object} props - required props in StickyActionTable
 * @returns  {React.ReactElement} - returns the StickyActionTable component
 */
const StickyActionTable = (props) => {
  const {
    headCells,
    gridData,
    isStickyAction,
    actions,
    actionHandler,
    enableSerial,
    enableAction
  } = props;

  return (
    // <Paper sx={{ borderRadius: '13px' }}>
    <TableContainer className="packagetableConatiner">
      <Table stickyHeader aria-label="sticky table" className="tablePackage">
        <TableHeader
          headCells={headCells}
          enableSerial={enableSerial}
          isStickyAction={isStickyAction}
          enableAction={enableAction}
        />
        <TableBodySection
          gridData={gridData}
          isStickyAction={isStickyAction}
          actions={actions}
          actionHandler={actionHandler}
          enableSerial={enableSerial}
          enableAction={enableAction}
        />
      </Table>
    </TableContainer>
    // </Paper>
  );
};

export default StickyActionTable;

StickyActionTable.propTypes = {
  isStickyAction: PropTypes.bool,
  enableSerial: PropTypes.bool,
  enableAction: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.string),
  headCells: PropTypes.arrayOf(PropTypes.objectOf),
  gridData: PropTypes.arrayOf(PropTypes.objectOf),
  actionHandler: PropTypes.func
};
StickyActionTable.defaultProps = {
  headCells: [],
  isStickyAction: true,
  enableSerial: false,
  enableAction: true,
  gridData: [],
  actions: [],
  actionHandler: () => null
};
