import React, { useState } from 'react';
import CustomButton from '../../../../components/atoms/CustomButton/Button';
import NewGeneralMaster from './generalMaster/MasterModal';

/**
 *
 * @returns {React.ReactElement} - returns the add new component
 */
const AddNew = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <CustomButton
        variants="text"
        color="info"
        btnTitle="Add new"
        TitleColor="quinary"
        iconType="plus"
        startIcon
        onClickHandle={() => setOpen(!open)}
        customClass="btnAdd"
      />
      {open && (
        <NewGeneralMaster
        // onCloseModal={(d) => setOpen(d)}
        // onClickHandleAction={() => setOpen(!open)}
        />
      )}
    </div>
  );
};

export default AddNew;
