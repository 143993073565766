import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import './staticBar.css';
/**
 *
 * @param {object} props - required props in static bar
 * @returns {React.ReactElement} - returns th static bar component
 */
const StaticBar = (props) => {
  const { barContent } = props;
  return (
    <Grid container>
      {barContent?.map((item) => {
        return (
          <Grid item md={3} className="barDiv">
            <Typography
              type="caption"
              text={item.text}
              colorType="senary"
              customClass="barText"
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default StaticBar;
StaticBar.propTypes = {
  barContent: PropTypes.arrayOf()
};
StaticBar.defaultProps = {
  barContent: []
};
