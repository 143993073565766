import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import colors from '../../../utils/colors/colors';
import './status.css';
/**
 *
 * @param {object} props - required props in StatusComponent
 * @returns {React.ReactElement} - returns the StatusComponent
 */
const StatusComponent = (props) => {
  const { enable, overAllStatus, onShowStatus } = props;
  return (
    <Grid
      className={`${overAllStatus && 'statusGrid'} statusText`}
      sx={{
        background:
          overAllStatus &&
          (enable ? colors.green.secondary : colors.orange.secondary)
      }}
      onClick={(e) => onShowStatus(e)}
    >
      <Grid
        className="point"
        sx={{
          background: enable ? colors.green.primary : colors.orange.primary
        }}
      />
      <Typography
        type="caption"
        text={enable ? 'Active' : 'Inactive'}
        colorType={enable ? 'active' : 'inActive'}
      />
    </Grid>
  );
};

StatusComponent.propTypes = {
  enable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overAllStatus: PropTypes.bool,
  onShowStatus: PropTypes.func
};
StatusComponent.defaultProps = {
  enable: null,
  overAllStatus: false,
  onShowStatus: () => null
};
export default StatusComponent;
