/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import TooltipContent from './TooltipContent/TooltipContent';
import StatusComponent from '../StatusComponent/statusComponent';
import './Tooltip.css';
/**
 *
 * @param  {object} props - required props in Tooltip component
 * @returns {React.ReactElement} - returns the Tooltip component
 */
const CustomTooltip = (props) => {
  const { tooltipTitle, onShowOrganization, enable, overAllStatus } = props;

  return (
    <div>
      <Tooltip
        title={
          <TooltipContent
            contentTitle={tooltipTitle}
            onShowOrganization={() => onShowOrganization()}
          />
        }
        placement="bottom-end"
      >
        <Box>
          <StatusComponent enable={enable} overAllStatus={overAllStatus} />
        </Box>
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;

CustomTooltip.propTypes = {
  tooltipTitle: PropTypes.string,
  onEdit: PropTypes.func,
  onShowOrganization: PropTypes.func,
  enable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overAllStatus: PropTypes.bool
};
CustomTooltip.defaultProps = {
  tooltipTitle: '',
  onEdit: () => null,
  onShowOrganization: () => null,
  enable: null,
  overAllStatus: false
};
