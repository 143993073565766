import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Typography from '../../atoms/Typography/Typography';
import CustomDropdown from '../../atoms/CustomDropdown/Dropdown';
import './DropdownLabel.css';
/**
 *
 * @param {object} props - required props in DropdownLabel component
 * @returns {React.ReactElement} returns the DropdownLabel component
 */
const DropdownLabel = (props) => {
  const { title, labelList, name, control, dropdown, pattern, requiredField } =
    props;
  /**
   *
   * @returns {string} - returns the pattern regex
   */
  const patternValidation = () => {
    switch (pattern) {
      case 'email':
        return /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      case 'number':
        return /^([0-9][0-9]{9,14})$/;
      case 'text':
        return /^[a-zA-Z ]*$/;
      default:
        return null;
    }
  };

  // const [quantity, setQuantity] = useState();

  // // setQuantity(value)
  // /**
  //  *
  //  * @param  {*} e - event on onchange function
  //  */
  // const handleChange = (e) => {
  //   const data = e.target.value;
  //   const obj = labelList.find((item) => {
  //     if (data === item.id) {
  //       return item;
  //     }
  //     return null;
  //   });
  //   getQuantity(obj);
  //   setQuantity(obj);
  // };

  return (
    <Grid>
      <Typography
        type="caption"
        text={title}
        customClass="labelTitle"
        colorType="senary"
        requiredField={requiredField}
      />

      <Grid display="flex">
        <div>
          <Controller
            name={name}
            control={control}
            rules={{
              required: true,
              min: 0,
              pattern: /^\d*\.?\d*$/,
              maxLength: 2
            }}
            render={({ field: { onChange, value } }) => (
              <input
                type="number"
                className="textField"
                onChange={(e) => onChange(e, patternValidation(pattern))}
                value={value || ''}
                min="0"
                maxLength={2}
              />
            )}
          />
        </div>
        <div className="dropGrid">
          <Controller
            name={dropdown}
            control={control}
            rules={{ required: true, pattern: /^\S.*$/ }}
            render={({ field: { onChange, value } }) => (
              <CustomDropdown
                name={dropdown}
                value={value || ''}
                data={labelList}
                handleChange={onChange}
                customClass="dropdown"
              />
            )}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default DropdownLabel;
DropdownLabel.propTypes = {
  title: PropTypes.string,
  labelList: PropTypes.arrayOf(PropTypes.objectOf),
  name: PropTypes.string,
  control: PropTypes.string,
  pattern: PropTypes.string,
  dropdown: PropTypes.string,
  requiredField: PropTypes.bool
};
DropdownLabel.defaultProps = {
  title: '',
  labelList: [],
  dropdown: '',
  name: '',
  pattern: '',
  control: '',
  requiredField: false
};
