import React from 'react';
import { Grid } from '@mui/material';
import Card from '../../../../components/atoms/Card/Card';
import CustomTextfield from '../../../../components/atoms/TextInput';
import CustomDropdown from '../../../../components/atoms/CustomDropdown/Dropdown';
import CustomButton from '../../../../components/atoms/CustomButton/Button';
import CustomDatePicker from '../../../../components/atoms/Pickers/DatePicker';
// import CustomTab from '../../../components/molecules/Tab';
import './serviceLevel.css';

/**
 * @returns {React.ReactElement} return the service level page
 */
const Servicelevel = () => {
  const data = [
    { id: 1, value: 'aaa' },
    { id: 2, value: 'bbb' }
  ];
  /**
   *@returns {any} --NULL
   */
  const onHandleChange = () => {
    return null;
  };
  return (
    <div className="mainGrid">
      <Grid item xs={12} container>
        <Card
          cardTitle="92822 - BLOOD TEST | ADD SERVICE LEVEL BASE RATE FOR ENTERPRISE"
          cardTitleType="caption"
        >
          <Grid container spacing={3} direction="row">
            <Grid item xs={2}>
              <CustomTextfield
                label="Entity Type"
                value="Enterprise"
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <CustomTextfield
                label="Entity Name"
                value="Apollo India"
                disabled
              />
            </Grid>
            <Grid item xs={2}>
              <CustomDropdown
                name="service"
                data={data}
                handleChange={() => null}
                label="Service Modifier"
              />
            </Grid>
            <Grid item xs={1}>
              <CustomTextfield label="Base Rate/MRP" values="10" />
            </Grid>
            <Grid item xs={1}>
              <CustomTextfield label="Cost Price" values="10" />
            </Grid>
            <Grid item xs={2}>
              <CustomDatePicker
                label="Effective From"
                onHandleChange={(e) => onHandleChange(e)}
              />
            </Grid>
            <Grid item xs={2}>
              <CustomDatePicker
                label="Effective To"
                onHandleChange={(e) => onHandleChange(e)}
              />
            </Grid>
          </Grid>
          <Grid
            spacing={2}
            container
            justifyContent="flex-end"
            className="btngrid"
          >
            <Grid item>
              <CustomButton
                type="primary"
                TitleColor="quinary"
                customClass="custombtn"
                btnTitle="Add another"
                onClickHandle={() => null}
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="primary"
                TitleColor="quaternary"
                customClass="custombtnsubmit"
                btnTitle="Submit"
                onClickHandle={() => null}
              />
            </Grid>
          </Grid>
          {/* <CustomTab /> */}
        </Card>
      </Grid>
    </div>
  );
};
export default Servicelevel;
