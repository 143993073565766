import React from 'react';
import { Divider, Grid } from '@mui/material';
import CustomButton from '../../../../../components/atoms/CustomButton/Button';
import CustomTextField from '../../../../../components/atoms/TextInput';
import CustomDatePicker from '../../../../../components/atoms/Pickers/DatePicker';
import CustomTypography from '../../../../../components/atoms/Typography/Typography';
import TreeViewStatus from '../../../../../components/organisms/TreeTable/TreeViewStatus';
// import CustomSwitch from '../../../../components/atoms/CustomSwitch/switch';
/**
 * @returns {React.ReactElement} return App bar and component
 */
const BulkAdd = () => {
  /**
   *@returns {any} --NULL
   */
  const onHandleChange = () => {
    return null;
  };

  return (
    <div>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={1.5}>
          <CustomTypography
            text="Status"
            type="caption"
            customClass="labelText"
            colorType="senary"
          />
          <CustomButton
            type="primary"
            TitleColor="quaternary"
            customClass="custombtnsubmit"
            btnTitle="Active"
            onClickHandle={() => null}
          />
        </Grid>
        <Grid item xs={1.5}>
          <CustomTypography
            text="."
            type="caption"
            customClass="labelText"
            colorType="senary"
          />
          <CustomButton
            type="primary"
            TitleColor="quinary"
            customClass="custombtn"
            btnTitle="InActive"
            onClickHandle={() => null}
          />
        </Grid>
        <Grid item xs={1.6}>
          <CustomDatePicker
            label="Effective From"
            onHandleChange={(e) => onHandleChange(e)}
          />
        </Grid>
        <Grid item xs={1.6}>
          <CustomDatePicker
            label="Effective to"
            onHandleChange={(e) => onHandleChange(e)}
          />
        </Grid>
        <Grid item xs={5.8}>
          <CustomTextField
            label="Reason"
            values="Duis porta, ligula rhoncus euismod pretium, nisi tellus"
          />
        </Grid>
      </Grid>
      <br />
      <Divider />
      <Grid>
        <TreeViewStatus />
      </Grid>
    </div>
  );
};

export default BulkAdd;
