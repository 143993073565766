/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { array, arrayOf, PropTypes } from 'prop-types';
import axios from 'axios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, Divider } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import moment from 'moment';
import Typography from '../../atoms/Typography/Typography';
import CustomButton from '../../atoms/CustomButton/Button';
import CustomTextField from '../../atoms/TextInput';
import CustomDropdown from '../../atoms/CustomDropdown/Dropdown';
import CustomCheckbox from '../../atoms/CustomCheckbox/checkbox';
import CustomDatePicker from '../../atoms/Pickers/DatePicker';
import colors from '../../../utils/colors/colors';
import Toaster from '../../atoms/Toaster/Toaster';
import './AddNew.css';
import Label from '../../atoms/CustomLabel/Label';
import PersentageInput from '../../atoms/DepositPersentageInput/Persentage';
import DepositRequirement from '../DepositeRequirement/DepositeRequirement';
import CriteriaLabel from '../../molecules/CriteriaLabel/CriteriaLabel';
import CustomTextArea from '../../atoms/CustomTextArea/TextArea';
import MultiSelect from '../../atoms/MultiSelectButton/MultiSelectButton';
import AddPackageDetails from '../../molecules/PackageDetailsAddNew/PackageDetails';
import ErrorIndicator from '../ErrorIndicator/ErrorIndicator';
import ComboText from '../../molecules/CombineComboText/ComboText';
import {
  REQUIRED,
  PATTERN,
  MIN,
  MAXLENGTH,
  ALLOWED_DIGITS,
  FIVE_DIGITS_ONLY
} from '../../../utils/Constants/ErrorMsg';
import StickyActionTable from '../StickyActionTable';

/**
 *
 * @param {object} props - required props in add new component
 * @returns {React.ReactElement} - returns the AddNew component
 */
const AddNew = (props) => {
  const {
    handleSubmit,
    onSubmit,
    addNewHead,
    divider,
    Controller,
    control,
    ArrayName,
    errors,
    waatchField,
    watchDateField,
    entries,
    customClass,
    valueDefault
    // maxLength
    // tabNewCount
  } = props;

  const [dropdownList, setDropdownList] = useState([]);
  const [addNewCount, setAddNewCount] = useState(0);
  const [successMsg, setSuccessMsg] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [successType, setType] = React.useState('');
  const [values, setValues] = React.useState(0);
  const [add, setAdd] = React.useState(false);
  const [input, setInput] = React.useState(true);
  const [handler, setHandler] = React.useState(false);
  const [btnControl, setBtnControl] = React.useState(false);
  const [table, setTable] = React.useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${ArrayName}`
  });
  /**
   *
   * @param {*} list - passing list of data
   */
  const onClickList = (list) => {
    if (list.id === '1') {
      setHandler(true);
    }
  };

  /**
   *
   */
  const onClickAddNew = () => {
    if (addNewCount === 0) {
      const defaultArr = [];
      if (waatchField?.[ArrayName]?.length > 0) {
        waatchField?.[ArrayName]?.map((item) => {
          Object.entries(item).map(([_key, value]) => {
            defaultArr.push(value);
            if (value === '') {
              setMsg('All Fields are Mandatory');
              setType('error');
              setSuccessMsg(true);
            }
          });
        });
      } else {
        setAddNewCount(addNewCount + 1);
      }
      // append({ ismandatory: false });
      // append({});
      // setAddNewCount(addNewCount + 1);
    }

    if (addNewCount >= 0) {
      const arr = [];
      if (waatchField?.[ArrayName]?.length > 0) {
        waatchField?.[ArrayName]?.map((item, index) => {
          setValues(values + 1);
          Object.entries(item).map(([_key, value], idx) => {
            arr.push(value);
            if (value === undefined || '') {
              setMsg('All Fields are Mandatory');
              setType('error');
              setSuccessMsg(true);
            }
            if (
              index === waatchField[ArrayName].length - 1 &&
              value !== undefined &&
              idx === Object.keys(item).length - 1 &&
              arr.includes(undefined) !== true
            ) {
              setSuccessMsg(false);
              append(valueDefault);
              setAddNewCount(addNewCount + 1);
            }
          });
        });
      }
    }
  };
  /**
   *
   */
  const onClickHandle1 = () => {
    setTable(true);
  };
  /**
   * @param {*} event - passing event
   * @param {*} reason - passing reason
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessMsg(false);
  };
  /**
   *
   */
  const handleBtn = () => {
    setAdd(true);
  };
  /**
   *
   */
  const handleInput = () => {
    setAdd(true);
    setInput(false);
  };
  const headCells = [
    'Charge Name/Criteria',
    'Encounter Type',
    'From Day',
    'To Day',
    'Qty Limit',
    'Amt Limit',
    'Limit Type',
    'Raise Order?',
    'Refundable?',
    // 'Refundable1?',
    // 'Refundable2?',
    // 'Refundable3?',
    'Order Catalog'
  ];

  const gridData = [
    {
      id: 111,
      isByCharge: true,
      name: 'Lorem Ipsum is simply dummy text of the printing  ',
      type: 34,
      'from?': '1',
      to: '2',
      qty: '3',
      amt: '2.00',
      limit: 'rre',
      raise: 'rre',
      refund: 'yes',
      // refund1: 'yes',
      // refund2: 'yes',
      // refund3: 'yes',
      refund4: 'yes'
    },
    {
      id: 222,
      isByCharge: true,
      name: 'charge name',
      type: 34,
      'from?': '1',
      to: '2',
      qty: '3',
      amt: '2.00',
      limit: 'rre',
      raise: 'rre',
      refund: 'yes',
      // refund1: 'yes',
      // refund2: 'yes',
      // refund3: 'yes',
      refund4: 'yes'
    }
  ];
  /**
   *
   */
  const apiCall = () => {
    const url = process.env.REACT_APP_URL2;
    axios({
      method: 'get',
      url: `${url}/masterDropDown/RVUNIT`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      const tempArr = [];

      response?.data.data.map((val) => {
        return tempArr.push({
          // eslint-disable-next-line no-underscore-dangle
          id: val._id,
          value: val.code
        });
      });

      setDropdownList(tempArr);
    });
  };
  useEffect(() => {
    apiCall();
  }, []);
  /**
   *
   * @returns {Array} - returns the array of data
   */
  const fieldArr = () =>
    fields.map(
      (field, idx) => (
        <>
          {entries.map((keyValue) => {
            if (keyValue.AddNewPackageDetails) {
              if (!btnControl) setBtnControl(true);
            }
            if (!keyValue.AddNewPackageDetails) {
              if (btnControl) setBtnControl(false);
            }
            return (
              <>
                {keyValue?.isTextField && (
                  <Grid item md={keyValue.breakpoint}>
                    <Grid height="100px">
                      <Controller
                        key={field.id}
                        rules={{
                          required: keyValue?.isRequired,
                          min: keyValue.min,
                          pattern: keyValue.pattern,
                          maxLength: keyValue.maxLength
                        }}
                        name={`${ArrayName}.${idx}.${keyValue?.name}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CustomTextField
                            label={keyValue?.label}
                            type={keyValue.type}
                            onChange={(e) => onChange(e)}
                            value={value}
                            maxLength={keyValue.maxLength}
                            max={keyValue.max}
                            id={keyValue.id}
                            disabled={keyValue.disabled}
                            // min={keyValue.min}
                          />
                        )}
                      />
                      {/* /* {!keyValue?.isRequired && <div style={{color:}}>gg</div>} */}
                      {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'required' && <ErrorIndicator errorMsg={REQUIRED} />}
                      {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'pattern' && (
                          <ErrorIndicator errorMsg={keyValue.errorMsg} />
                        )}
                      {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'min' && <ErrorIndicator errorMsg={MIN} />}
                      {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'maxLength' && (
                          <ErrorIndicator errorMsg={keyValue.errorMsg} />
                        )}
                    </Grid>
                  </Grid>
                )}
                {keyValue?.isDropdown && (
                  <Grid item md={keyValue.breakpoint}>
                    <Grid height="100px">
                      <Controller
                        key={field.id}
                        name={`${ArrayName}.${idx}.${keyValue?.name}`}
                        control={control}
                        rules={{
                          required: keyValue?.isRequired,
                          min: keyValue.min,
                          pattern: keyValue.pattern,
                          maxLength: keyValue.maxLength
                        }}
                        render={({ field: { onChange, value } }) => (
                          <CustomDropdown
                            data={keyValue?.data}
                            label={keyValue?.label}
                            value={value}
                            handleChange={onChange}
                            name="userGroup"
                          />
                        )}
                      />
                      {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'required' && <ErrorIndicator errorMsg={REQUIRED} />}
                    </Grid>
                  </Grid>
                )}
                {keyValue?.isComboText && (
                  <Grid item md={keyValue.breakpoint}>
                    <Grid height="100px">
                      <Controller
                        key={field.id}
                        name={`${ArrayName}.${idx}.${keyValue?.name}`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <ComboText
                            textLable={keyValue.textLable}
                            dropDownLable={keyValue.dropDownLable}
                            dataList={keyValue.dataList}
                            onChangeCombo={onChange}
                            dropValue={value}
                            dropBreak={keyValue.dropBreak}
                            textBreak={keyValue.textBreak}
                            textDisable
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {keyValue?.isCheckBox && (
                  <Grid item md={keyValue.breakpoint}>
                    <Grid height="100px">
                      <Controller
                        key={field.id}
                        name={`${ArrayName}.${idx}.${keyValue?.name}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CustomCheckbox
                            checked={value || ''}
                            onHandleChange={(e) => onChange(e)}
                            value={value}
                            checkboxLabel={keyValue.label}
                            placement={keyValue.placement}
                            customClasses="addCheck"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {keyValue?.isDatePicker && (
                  <Grid item md={keyValue.breakpoint}>
                    <Grid height="100px">
                      <Controller
                        key={field.id}
                        name={`${ArrayName}.${idx}.${keyValue?.name}`}
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CustomDatePicker
                            label={keyValue.label}
                            onHandleChange={(e) =>
                              onChange(moment(e).format('YYYY-MM-DD'))
                            }
                            value={value}
                            minDate={
                              keyValue.name === 'effto'
                                ? watchDateField[idx]?.efffr
                                : keyValue.name === 'efffr' &&
                                  new Date('1920/06/20')
                            }
                            maxDate={
                              keyValue.name === 'efffr'
                                ? watchDateField[idx]?.effto
                                : keyValue.name === 'effto' &&
                                  new Date('2100/12/20')
                            }
                          />
                        )}
                      />
                      {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'required' && <ErrorIndicator errorMsg={REQUIRED} />}
                    </Grid>
                  </Grid>
                )}
                {keyValue.isDelete && addNewCount !== 0 && (
                  <Grid item md={keyValue.breakpoint}>
                    <Grid
                      className="delete"
                      onClick={() => {
                        remove(idx);
                        setAddNewCount(addNewCount - 1);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </Grid>
                  </Grid>
                )}
                {keyValue.isEmptySpace && (
                  <Grid item md={keyValue.breakpoint} />
                )}
                {keyValue.labelBtn && input && (
                  <Grid item md={keyValue.breakpoint}>
                    <DepositRequirement
                      Controller={Controller}
                      control={control}
                      handleBtn={handleBtn}
                      handleInput={handleInput}
                      values="Add New Rule Set"
                    />
                  </Grid>
                )}
                {keyValue.CriteriaLabel && add && <CriteriaLabel />}
                {keyValue.TextArea && (
                  <Grid item md={keyValue.breakpoint}>
                    <Controller
                      name="code"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <CustomTextArea
                          label={keyValue.label}
                          type="text"
                          onChange={(e) => onChange(e)}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                )}
                {keyValue.HR && (
                  <Grid item md={keyValue.breakpoint}>
                    <hr />
                  </Grid>
                )}
                {keyValue.Typography && (
                  <Grid item md={keyValue.breakpoint}>
                    <Typography
                      colorType="dark"
                      text="Package Discount Based"
                      type="link"
                    />
                  </Grid>
                )}
                {keyValue.MultiSelect && (
                  <Grid item md={keyValue.breakpoint}>
                    <MultiSelect
                      listItemData={keyValue.data}
                      onClickList={onClickList}
                    />
                  </Grid>
                )}
                {keyValue.AddNewPackageDetails && !table && (
                  <>
                    <AddPackageDetails
                      control={control}
                      values={keyValue.RelativeChargingEntries}
                      onClickHandle1={onClickHandle1}
                      errors={errors}
                      Controller={Controller}
                    />
                  </>
                )}
                {/* {keyValue.persentageInput && (
                  <Grid item md={keyValue.breakpoint}>
                    <PersentageInput
                      placeholder={keyValue.placeholder}
                      Category={keyValue.Category}
                      label={keyValue.label}
                    />
                  </Grid>
                )} */}
              </>
            );
          })}

          {/* {errors &&
                        errors[ArrayName] &&
                        errors[ArrayName][idx][keyValue?.name] &&
                        errors[ArrayName][idx][keyValue?.name]?.type ===
                          'maxLength' && (
                          <ErrorIndicator errorMsg={ALLOWED_DIGITS} />
                        )} */}
        </>
      )
      // ),
      // [
      //   fields,
      //   entries,
      //   ArrayName,
      //   control,
      //   errors,
      //   remove,
      //   addNewCount,
      //   watchDateField,
      //   add,
      //   input,
      //   Controller,
      //   handler
      // ]
    );
  return (
    <Grid className="input">
      {successMsg && (
        <Toaster
          type={successType}
          open={successMsg}
          text={msg}
          handleClose={handleClose}
        />
      )}
      {btnControl === false && (
        <Grid
          container
          pt={1.5}
          pb={1}
          justifyContent="space-between"
          alignItems="center"
          item
          md={12}
        >
          <Typography
            type="caption"
            text={addNewHead}
            customClass={`${customClass} additional`}
          />

          <CustomButton
            variants="text"
            color="info"
            btnTitle="Add new"
            TitleColor="quinary"
            iconType="plus"
            startIcon
            onClickHandle={() => {
              onClickAddNew();
            }}
            customClass="btnAdd"
          />
        </Grid>
      )}
      {divider && (
        <Divider
          sx={{ border: '1px solid', borderColor: colors.blue.tertiary }}
        />
      )}
      <Grid container spacing={2} mt={0} md={12} alignItems="center">
        {/* {`${ArrayName}`.map((val) => {
          
         return ( */}
        {fieldArr()}
        {/* })} */}
      </Grid>
    </Grid>
  );
};
export default AddNew;
AddNew.propTypes = {
  addNewHead: PropTypes.string,
  divider: PropTypes.bool,
  ArrayName: PropTypes.string,
  Controller: PropTypes.node,
  control: PropTypes.node,
  entries: PropTypes.arrayOf(PropTypes.objectOf),
  waatchField: PropTypes.func,
  watchDateField: PropTypes.shape(PropTypes.arrayOf),
  customClass: PropTypes.string,
  valueDefault: PropTypes.shape(PropTypes.Object)
};
AddNew.defaultProps = {
  addNewHead: '',
  divider: false,
  ArrayName: 'Test',
  Controller: <> </>,
  control: <> </>,
  entries: [],
  waatchField: () => null,
  watchDateField: [],
  customClass: '',
  valueDefault: {}
};
