import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import CustomTypography from '../../../atoms/Typography/Typography';

/**
 *
 * @param {object} props - required props in EnhancedTableHead
 * @returns  {React.ReactElement} - returns the EnhancedTableHead component
 */
const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  /**
   *
   * @param {*} property - passing property
   * @returns {Function}- returns the event and property
   */
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="tableHead">
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            size="small"
            padding="normal"
            sx={{ fontFamily: 'Poppins_Regular' }}
            className="theadRow"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <CustomTypography
                type="caption"
                text={headCell.label}
                colorType="senary"
              />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;

EnhancedTableHead.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};
