/* eslint-disable require-jsdoc */
import React from 'react';
import TreeViewTable from '.';
import './tree.css';

const TreeViewStatus = () => {
  return (
    <div>
      <TreeViewTable />
    </div>
  );
};

export default TreeViewStatus;
