/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import mockData from './mockData.json';
import './tree.css';
import CustomTypography from '../../atoms/Typography/Typography';
import Building from '../../../assets/icons/buildings.svg';
import CustomCheckbox from '../../atoms/CustomCheckbox/checkbox';
import CustomTooltip from '../../atoms/ToolTip/Tooltip';

/**
 *
 * @returns {React.ReactElement} - returns the TableHeader component
 */
const TableHeader = () => {
  return (
    <Grid
      className="statusHeader"
      container
      direction="row"
      alignItems="center"
    >
      <Grid item xs={3}>
        <CustomTypography
          customClass="entityTitle"
          colorType="senary"
          type="caption"
          text="Entity"
        />
      </Grid>
      <Grid item xs={1.5}>
        <CustomTypography
          colorType="senary"
          type="caption"
          text="EffectiveFrom"
          customClass="effectivefrom"
        />
      </Grid>
      <Grid item xs={1.5}>
        <CustomTypography
          colorType="senary"
          type="caption"
          text="EffectiveTo"
          customClass="effectiveto"
        />
      </Grid>
      <Grid item xs={5}>
        <CustomTypography
          colorType="senary"
          type="caption"
          text="Reason"
          customClass="reason"
        />
      </Grid>
      <Grid item xs={1}>
        <CustomTypography
          colorType="senary"
          type="caption"
          text="Status"
          customClass="status"
        />
      </Grid>
    </Grid>
  );
};
/**
 *
 * @param {object} props - required props in TableRowItem
 * @returns {React.ReactElement} - retunrs the TableRowItem
 */
const TableRowItem = (props) => {
  const { data } = props;
  /**
   *
   * @param {string} value -- entry key names
   * @returns {string} CLASSNAMES
   */
  const getRespectiveDiv = (value) => {
    switch (value) {
      case 'entity':
        return 'entityDiv';
      case 'effectiveFrom':
        return 'effectiveFromDiv';
      case 'effectiveTo':
        return 'effectiveToDiv';
      case 'reason':
        return 'reasonDiv';
      case 'status':
        return 'statusDiv';
      case 'children':
        return 'childrenDiv';
      default:
        return null;
    }
  };

  return (
    <>
      {Object.entries(data).map(([k, v], index) => {
        return (
          <>
            {k !== 'id' && k !== 'status' && (
              <div className={getRespectiveDiv(k)}>
                {k !== 'id' && k !== 'status' && Array.isArray(v) ? null : v}
              </div>
            )}
            {k === 'status' && (
              <div className="toolDiv">
                <CustomTooltip tooltipTitle="Status" enable />
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

/**
 * @param {*} data -all data
 * @name TreeViewTable
 * @returns {React.ReactElement} --Custom Functional component
 */
const getRenderTreeItem = (data) => {
  return (
    <TreeItem
      sx={{ maxWidth: '100%' }}
      key={data.id}
      nodeId={data.id}
      label={
        // <sapn style={{ display: 'flex' }}>
        //   <CustomCheckbox size="small" />
        <TableRowItem data={data} style={{ alignItems: 'center' }} />
        // </sapn>
      }
    >
      {Array.isArray(data.children)
        ? data.children.map((node) => getRenderTreeItem(node))
        : null}
    </TreeItem>
  );
};

/**
 * @param {*} props -all props
 * @name TreeViewTable
 * @returns {React.ReactElement} --Custom Functional component
 */
const TreeViewRow = (props) => {
  const { data } = props;

  return (
    <TreeView
      aria-label="customized"
      defaultExpanded={['1', '1.1', '1.2']}
      defaultCollapseIcon={<img src={Building} alt="img" />}
      defaultExpandIcon={<img src={Building} alt="img" />}
      //   defaultExpandIcon={<PlusSquare />}
      // defaultEndIcon={<CloseSquare />}
      // sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {getRenderTreeItem(data)}
    </TreeView>
  );
};

/**
 *
 * @param  {object} props - required props in TableBodySection
 * @returns {React.ReactElement} - returns the TableBodySection
 */
const TableBodySection = (props) => {
  const { gridConfig } = props;
  return (
    <div>
      {gridConfig.map((row) => (
        <TreeViewRow data={row} />
      ))}
    </div>
  );
};

/**
 * @param {*} props -all props
 * @name TreeViewTable
 * @returns {React.ReactElement} --Custom Functional component
 */
const TreeViewTable = (props) => {
  const { data } = props;
  /**
   *
   * @param {Array} val - passing array of elements
   * @returns {Function} - --
   */
  const callRec = (val) => {
    const finalArr = [];
    if (val && val.length > 0) {
      val.map((item, index) => {
        const hasChildArr = Array.isArray(item.children);
        hasChildArr ? item.children.map((node) => callRec(node)) : null;
        return console.log(item, index);
      });
    }
    return finalArr;
  };

  useEffect(() => {}, [callRec]);
  return (
    <div>
      <TableHeader />
      <Divider />
      <TableBodySection gridConfig={mockData.data} />
    </div>
  );
};

TreeViewTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf())
};

TreeViewTable.defaultProps = {
  data: []
};

export default TreeViewTable;
