import React, { useState } from 'react';
import './search.css';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import customIcons from '../../../utils/icons/icons';

/**
 * @param {object} props -- Customization props for this component
 * @returns {React.ReactElement} --Customized Search field
 */
const SearchField = (props) => {
  const { placeholder, onChangeHandler, handleClear, customClass } = props;
  const [showCross, setShowCross] = useState(false);
  const [searchedVal, setSearchedVal] = useState();
  /**
   *
   */
  const onCrossClick = () => {
    setShowCross(false);
    setSearchedVal('');
  };
  /**
   *@param {event} e event
   */
  const changeIcon = (e) => {
    setShowCross(e.target.value !== '');
    setSearchedVal(e.target.value);
  };
  return (
    <div className="searchDiv">
      <div className="searchIcon">
        <SearchIcon />
      </div>
      <input
        value={searchedVal}
        type="search"
        placeholder={placeholder}
        onChange={(d) => {
          onChangeHandler(d);
          changeIcon(d);
        }}
        className={`${customClass} customSearch`}
      />
      {showCross && (
        <img
          src={customIcons.CrossIcon}
          alt="Search"
          aria-hidden
          className="crossIcon"
          onClick={() => {
            onCrossClick();
            handleClear();
          }}
        />
      )}
    </div>
  );
};

SearchField.propTypes = {
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  customClass: PropTypes.string
};

SearchField.defaultProps = {
  placeholder: '',
  handleClear: () => null,
  customClass: ''
};

export default SearchField;
