/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Typography from '../../atoms/Typography/Typography';
import './Tab.css';

/**
 * @name  CustomTab
 * @param {*} root0 props
 * @param {object} root0.TabContent contains tab component details
 * @param {object} root0.handleTabChange  tab on change function
 * @returns {React.ReactElement} - returns Typography component
 */
const CustomTab = ({ handleTabChange, id }) => {
  const [value, setValue] = React.useState(0);
  const [TabContent, setTabContent] = useState([]);
  const url = process.env.REACT_APP_URL;
  React.useEffect(() => {
    if (id === '450949608') {
      setValue(0);
      handleTabChange(0);
    }
    if (id === '393824690') {
      setValue(0);
      handleTabChange(0);
    }
  }, [id]);

  useEffect(() => {
    axios({
      method: 'post',
      url: `${url}/read_documents`,
      data: {
        db_name: 'RCM',
        entity: 'tab',
        filter: `tab._id == "tab/${id}"`,
        return_fields: 'tab'
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      setTabContent(response?.data.result);
    });
  }, [url, id]);
  /**
   * @param {number} onChangeValue input view data index
   */
  const handleChange = (onChangeValue) => {
    handleTabChange(onChangeValue);
    setValue(onChangeValue);
  };

  return (
    <>
      {TabContent && TabContent[0]?.Active && (
        <div className="col-md-6 box_ui">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderColor: 'divider' }}>
                <TabList
                  TabIndicatorProps={{
                    style: {
                      background:
                        TabContent &&
                        TabContent[0]?.children?.Titles?.TabStyles?.TabIndicator
                    }
                  }}
                  textColor="inherit"
                >
                  {TabContent &&
                    TabContent[0]?.children?.Titles?.DisplayText?.map(
                      (data, index) => {
                        const key = index;
                        return (
                          <Tab
                            key={key}
                            onClick={() => handleChange(index)}
                            label={
                              <Typography
                                text={data.Text}
                                customStyle={
                                  TabContent &&
                                  TabContent[0]?.children?.Titles?.Typography
                                }
                              />
                            }
                            value={index}
                          />
                        );
                      }
                    )}
                </TabList>
              </Box>
            </TabContext>
          </Box>
        </div>
      )}
    </>
  );
};

CustomTab.propTypes = {
  handleTabChange: PropTypes.func,
  id: PropTypes.string.isRequired
};

CustomTab.defaultProps = {
  handleTabChange: () => {}
};
export default CustomTab;
