import React, { useState } from 'react';
// import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CustomModal from '../../../../../components/molecules/Modal/modal';
import ViewStatusContent from './viewStatusContent';
// import ViewBulkStatus from './viewBulkStatus';
import BulkAdd from './bulkAdd';
// import CustomButton from '../../../../components/atoms/CustomButton/Button';
/**
 *
 * @param {object} props - required props in modal content
 * @returns {React.ReactElement}- returns the master modal content
 */
const ViewStatus = (props) => {
  const { onCloseModal } = props;
  const [bulkAdd, setbulkAdd] = useState(false);
  /**
   *
   */
  const onBulkaddcontent = () => {
    setbulkAdd(true);
  };
  return (
    <div>
      <CustomModal
        openModal
        modalTitle={bulkAdd ? 'Add Bulk Status by Entity' : 'Status by Entity'}
        fontSize="14px"
        modalContent={bulkAdd ? <BulkAdd /> : <ViewStatusContent />}
        onClose={() => onCloseModal(false)}
        onBulkadd={() => onBulkaddcontent()}
        modalHeadAction={!bulkAdd}
      />
    </div>
  );
};

export default ViewStatus;
ViewStatus.propTypes = {
  onCloseModal: PropTypes.func
  //   onClickHandleAction: PropTypes.func
  //   onClickAddNew: PropTypes.func
};
ViewStatus.defaultProps = {
  onCloseModal: () => null
  //   onClickHandleAction: () => null
  //   onClickAddNew: () => null
};
