import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { actions } from 'rcm-binder';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../../components/atoms/Card/Card';
import CustomTextfield from '../../../../components/atoms/TextInput';
import CustomDropdown from '../../../../components/atoms/CustomDropdown/Dropdown';
import buildings from '../../../../assets/icons/buildings.svg';
import calender from '../../../../assets/icons/calendar.svg';
import content from '../../../../assets/icons/content.svg';
import CustomCheckbox from '../../../../components/atoms/CustomCheckbox/checkbox';
import MenuSelect from '../../../../components/atoms/MenuSelect/MenuSelect';
import DropdownLabel from '../../../../components/molecules/DropdownLabel/DropdownLabel';
import Typography from '../../../../components/atoms/Typography/Typography';
import './chargedefinition.css';
import CustomTab from '../../../../components/molecules/Tab';
import TabData from './TabData';
import CustomSwitch from '../../../../components/atoms/CustomSwitch/switch';
import StatusBar from '../../../../components/organisms/StatusBar/StatusBar';
import ErrorIndicator from '../../../../components/organisms/ErrorIndicator/ErrorIndicator';

import {
  REQUIRED,
  PATTERN,
  MIN,
  MAXLENGTH,
  ALLOWED_DIGITS
} from '../../../../utils/Constants/ErrorMsg';
/**
 * @param {object} props - required props in Chargedefinition
 * @returns {React.ReactElement} return the charge definition page
 */
const Chargedefinition = (props) => {
  const {
    control,
    handleSubmit,
    onSubmit,
    errors,
    defaultValues,
    watchField,
    watchDateField,
    watchSwitchField
  } = props;
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  /**
   *
   */
  const onOpenModal = () => {
    setOpen(true);
  };
  /**
   *
   */
  const onClickHandleAction = () => {
    setOpen(false);
  };
  const data = [
    { id: 1, value: 'INV' },
    { id: 2, value: 'ORG' }
  ];

  const datastatus = [
    {
      Image: buildings,
      head: 'Enterprise',
      body: 'AINQA - INDIA'
    },
    {
      Image: calender,
      head: 'Effective From',
      body: '12-06-2020'
    },
    {
      Image: calender,
      head: 'Effective To',
      body: '12-06-2020'
    },
    {
      Image: content,
      head: 'Reason',
      body: 'Quisque suscipit ipsum est, eu venenatis leo ornare ege.'
    }
  ];
  const [chargeType, setChargeType] = useState([data]);
  const [chargeGroup, setChargeGroup] = useState([data]);
  const [chargeCategory, setChargeCategory] = useState([data]);
  const [encounterType, setEncounterType] = useState([data]);
  const [UOMType, setUOMType] = useState([data]);
  const [changeCodeSet, setChangeCodeSet] = React.useState([data]);
  const { masterDropdown_read } = useSelector(
    (state) => state.masterDropdownReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const chargeCode = [];

    masterDropdown_read.CHARGECODESET?.data.map((val, index) => {
      if (index !== 5) {
        const newLocal = '_id';
        return chargeCode.push({
          id: val[newLocal],
          value: val.code
        });
      }
      return null;
    });
    setChangeCodeSet(chargeCode);
  }, [masterDropdown_read.CHARGECODESET?.data]);

  useEffect(() => {
    const encounterArr = [];
    masterDropdown_read.ENCOUNTERTYPE?.data.map((val, index) => {
      if (index !== 5) {
        const newLocal = '_id';
        return encounterArr.push({
          id: val[newLocal],
          value: val.code
        });
      }
      return null;
    });
    setEncounterType(encounterArr);
  }, [masterDropdown_read.ENCOUNTERTYPE?.data]);

  useEffect(() => {
    const uomArr = [];
    masterDropdown_read.UOM?.data.map((val) => {
      const newLocal = '_id';
      return uomArr.push({
        id: val[newLocal],
        value: val.code
      });
    });
    setUOMType(uomArr);
  }, [masterDropdown_read.UOM?.data]);

  useEffect(() => {
    const chargeGrp = [];
    masterDropdown_read.CHARGEGROUP?.data.map((val) => {
      const newLocal = '_id';
      return chargeGrp.push({
        id: val[newLocal],
        value: val.code
      });
    });
    setChargeGroup(chargeGrp);
  }, [masterDropdown_read.CHARGEGROUP?.data]);

  useEffect(() => {
    const chargeTypeArr = [];
    masterDropdown_read.CHARGETYPE?.data.map((val) => {
      const newLocal = '_id';
      return chargeTypeArr.push({
        id: val[newLocal],
        value: val.code
      });
    });
    setChargeType(chargeTypeArr);
  }, [masterDropdown_read.CHARGETYPE?.data]);

  useEffect(() => {
    const categoryType = [];
    masterDropdown_read.CHARGECATEGORY?.data.map((val) => {
      const newLocal = '_id';
      return categoryType.push({
        id: val[newLocal],
        value: val.code
      });
    });
    setChargeCategory(categoryType);
  }, [masterDropdown_read.CHARGECATEGORY?.data]);
  /**
   *
   */
  const apiCall = useCallback(() => {
    dispatch(actions.MASTER_DROPDOWN('ENCOUNTERTYPE'));
    dispatch(actions.MASTER_DROPDOWN('CHARGECODESET'));
    dispatch(actions.MASTER_DROPDOWN('UOM'));
    dispatch(actions.MASTER_DROPDOWN('CHARGEGROUP'));
    dispatch(actions.MASTER_DROPDOWN('CHARGETYPE'));
    dispatch(actions.MASTER_DROPDOWN('CHARGECATEGORY'));
  }, [dispatch]);

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="chargemasterGrid">
      <form id="ChargeMaster" onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <StatusBar
            cardTitle="STATUS"
            datastatus={datastatus}
            onOpenModal={onOpenModal}
            onClickHandleAction={onClickHandleAction}
            open={open}
          />
        </Grid>
        <div className="chargeGrid">
          <Card cardTitle="CHARGE DETAILS" cardTitleType="caption">
            <Grid
              container
              spacing={3}
              direction="row"
              className="removeMargin"
            >
              <Grid item xs={2}>
                <Controller
                  name="chargecodesetid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      label="Charge Code Set"
                      data={changeCodeSet}
                      value={value || ''}
                      handleChange={onChange}
                      name="userGroup"
                      requiredField
                    />
                  )}
                />
                {errors?.chargecodesetid &&
                  errors?.chargecodesetid?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="chargecode"
                  rules={{
                    required: true,
                    maxLength: 20,
                    pattern:
                      /^(?!\s)(?!.*\s$)[A-Za-z0-9][A-Za-z0-9\\_\\.\-\\,\s]*$/
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextfield
                      label="Charge Code"
                      values="92982"
                      value={value}
                      onChange={onChange}
                      maxLength="20"
                      id="ChargeCode"
                      requiredField
                    />
                  )}
                />
                {errors?.chargecode &&
                  errors?.chargecode?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
                {errors?.chargecode &&
                  errors?.chargecode?.type === 'pattern' && (
                    <ErrorIndicator errorMsg={PATTERN} />
                  )}
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="shortdesc"
                  rules={{
                    required: true,
                    maxLength: 60,
                    // pattern: /^\S.*$/
                    pattern:
                      /^(?!\s)(?!.*\s$)[A-Za-z0-9][A-Za-z0-9\\_\\.\-\\,\s]*$/
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextfield
                      onChange={(d) => onChange(d)}
                      label="Short Description"
                      value={value}
                      maxLength="60"
                      requiredField
                    />
                  )}
                />
                {errors?.shortdesc &&
                  errors?.shortdesc?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}

                {errors?.shortdesc && errors?.shortdesc?.type === 'pattern' && (
                  <ErrorIndicator errorMsg={PATTERN} />
                )}
              </Grid>

              <Grid item xs={5}>
                <Controller
                  name="longdesc"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 200,
                    pattern:
                      /^(?!\s)(?!.*\s$)[A-Za-z0-9][A-Za-z0-9\\_\\.\-\\,\s]*$/
                  }}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextfield
                      label="Long Description"
                      values="Blood Test"
                      onChange={onChange}
                      value={value}
                      maxLength="200"
                      requiredField
                    />
                  )}
                />
                {errors?.longdesc && errors?.longdesc?.type === 'required' && (
                  <ErrorIndicator errorMsg={REQUIRED} />
                )}
                {errors?.longdesc && errors?.longdesc?.type === 'pattern' && (
                  <ErrorIndicator errorMsg={PATTERN} />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="insideGrid">
              <Grid item xs={4}>
                <Controller
                  name="chargetype"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      data={chargeType}
                      handleChange={onChange}
                      value={value || ''}
                      label="Charge Type"
                      name="Charge Type"
                      requiredField
                    />
                  )}
                />
                {errors?.chargetype &&
                  errors?.chargetype?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="chargegrp"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      data={chargeGroup}
                      handleChange={onChange}
                      value={value || ''}
                      label="Charge Group"
                      name="Charge Group"
                      requiredField
                    />
                  )}
                />
                {errors?.chargegrp &&
                  errors?.chargegrp?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="chargecatg"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      data={chargeCategory}
                      handleChange={onChange}
                      value={value || ''}
                      label="Charge Category"
                      name="Charge Category"
                      requiredField
                    />
                  )}
                />
                {errors?.chargecatg &&
                  errors?.chargecatg?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="insideGrid">
              <Grid item>
                <Controller
                  name="isuserrateentry"
                  control={control}
                  render={({ field }) => (
                    <CustomCheckbox
                      placement="end"
                      checkboxLabel="Rate Entry By User?"
                      onHandleChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="isuserdescentry"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckbox
                      checked={value || ''}
                      placement="end"
                      value={value || ''}
                      onHandleChange={onChange}
                      checkboxLabel="Description Update By User?"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="isdirectentry"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckbox
                      checked={value || ''}
                      placement="end"
                      value={value || ''}
                      onHandleChange={onChange}
                      checkboxLabel="Direct Entry?"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="ismatlitem"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckbox
                      placement="end"
                      onHandleChange={onChange}
                      checkboxLabel="Material Item?"
                      checked={value || ''}
                      value={value || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="isnonpatservice"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckbox
                      checked={value || ''}
                      placement="end"
                      value={value || ''}
                      onHandleChange={onChange}
                      checkboxLabel="Non Patient Service?"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name="iscaptureperfdept"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckbox
                      checked={value || ''}
                      placement="end"
                      value={value}
                      onHandleChange={onChange}
                      checkboxLabel="Capture Performing Dept?"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>
        </div>
        <Grid item xs={12} container className="applicableGrid">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={3}>
              <Card
                cardTitle="APPLICABLE ENCOUNTER TYPES"
                cardTitleType="caption"
                customClass="validBottom"
              >
                <Grid container>
                  <Typography
                    type="caption"
                    text="Encounter Types"
                    customClass="labelText"
                    colorType="senary"
                    requiredField
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="menubtn"
                  container
                  spacing={1}
                  direction="row"
                >
                  {encounterType?.map((val) => (
                    <Grid item>
                      <Controller
                        name={`${val?.value}`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <MenuSelect
                            customClass="customStyle"
                            defaultSelect={defaultValues[val?.value]}
                            type="primary"
                            TitleColor="quaternary"
                            btnName={val?.value}
                            handleClick={(e) => {
                              onChange(e);
                            }}
                            value={value || ''}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                cardTitle="QUANTITY"
                cardTitleType="caption"
                customClass="validBottom"
              >
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item xs={8}>
                    <DropdownLabel
                      control={control}
                      labelList={UOMType}
                      title="Base Qty"
                      name="baseqty"
                      dropdown="baseuom"
                      errors={errors}
                      requiredField
                    />
                    {errors?.baseuom &&
                      errors?.baseuom?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.baseqty &&
                      errors?.baseqty?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.baseqty && errors?.baseqty?.type === 'min' && (
                      <ErrorIndicator errorMsg={MIN} />
                    )}
                    {errors?.baseqty &&
                      errors?.baseqty?.type === 'maxLength' && (
                        <ErrorIndicator errorMsg={` ${ALLOWED_DIGITS} 99`} />
                      )}
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="maxtxnqty"
                      control={control}
                      rules={{ required: true, min: 0, maxLength: 2 }}
                      render={({ field: { onChange, value } }) => (
                        <CustomTextfield
                          label="Max Qty/Trx"
                          values="Blood Test"
                          onChange={onChange}
                          value={value || ''}
                          maxLength="2"
                          type="number"
                          requiredField
                        />
                      )}
                    />
                    {errors?.maxtxnqty &&
                      errors?.maxtxnqty?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.maxtxnqty && errors?.maxtxnqty?.type === 'min' && (
                      <ErrorIndicator errorMsg={MIN} />
                    )}
                    {errors?.maxtxnqty &&
                      errors?.maxtxnqty?.type === 'maxLength' && (
                        <ErrorIndicator errorMsg={` ${ALLOWED_DIGITS} 99`} />
                      )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={5}>
              <Card
                cardTitle="MAX SERVICE QTY CHECK FOR A DURATION"
                cardTitleType="caption"
                customClass="validBottom"
              >
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item xs={5}>
                    <DropdownLabel
                      control={control}
                      labelList={UOMType}
                      title="Max Order Quantity"
                      name="maxordqtyval"
                      dropdown="maxordqtyuom"
                      requiredField
                    />
                    {errors?.maxordqtyuom &&
                      errors?.maxordqtyuom?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.maxordqtyval &&
                      errors?.maxordqtyval?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.maxordqtyval &&
                      errors?.maxordqtyval?.type === 'pattern' && (
                        <ErrorIndicator errorMsg={PATTERN} />
                      )}
                    {errors?.maxordqtyval &&
                      errors?.maxordqtyval?.type === 'min' && (
                        <ErrorIndicator errorMsg={MIN} />
                      )}
                    {errors?.maxordqtyval &&
                      errors?.maxordqtyval?.type === 'maxLength' && (
                        <ErrorIndicator errorMsg={MAXLENGTH} />
                      )}
                  </Grid>
                  <Grid item xs={5}>
                    <DropdownLabel
                      control={control}
                      labelList={UOMType}
                      title="Within Duration Value"
                      name="maxdurnval"
                      dropdown="maxdurnuom"
                      requiredField
                    />
                    {errors?.maxdurnval &&
                      errors?.maxdurnval?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.maxdurnuom &&
                      errors?.maxdurnuom?.type === 'required' && (
                        <ErrorIndicator errorMsg={REQUIRED} />
                      )}
                    {errors?.maxdurnval &&
                      errors?.maxdurnval?.type === 'pattern' && (
                        <ErrorIndicator errorMsg={PATTERN} />
                      )}
                    {errors?.maxdurnval &&
                      errors?.maxdurnval?.type === 'min' && (
                        <ErrorIndicator errorMsg={MIN} />
                      )}
                  </Grid>

                  <Grid item xs={2}>
                    <div className="Switch">Package?</div>
                    <div className="SwitchBar">
                      <Controller
                        name="isPackage"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <CustomSwitch
                            name="isPackage"
                            onChange={onChange}
                            value={value}
                            switchColor="switch"
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="applicableGrid">
          <div className="tabs">
            <CustomTab
              handleTabChange={(e) => setTabIndex(e)}
              id={watchSwitchField ? '450949608' : '393824690'}
            />
            <TabData
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              tab={tabIndex}
              Controller={Controller}
              control={control}
              errors={errors}
              watchField={watchField}
              watchDateField={watchDateField}
              watchSwitchField={watchSwitchField}
              // tabNewCount={tabNewCount}
            />
          </div>
        </Grid>
      </form>
    </div>
  );
};
export default Chargedefinition;

Chargedefinition.propTypes = {
  control: PropTypes.node,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.shape(PropTypes.Object),
  defaultValues: PropTypes.shape(PropTypes.Object),
  watchField: PropTypes.func,
  watchDateField: PropTypes.shape(PropTypes.arrayOf),
  watchSwitchField: PropTypes.bool
};
Chargedefinition.defaultProps = {
  control: <> </>,
  handleSubmit: () => null,
  onSubmit: () => null,
  errors: {},
  defaultValues: {},
  watchField: () => null,
  watchDateField: [],
  watchSwitchField: false
};
