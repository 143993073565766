import { createTheme } from '@mui/material/styles';
import colors from '../../utils/colors/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue.primary
    },
    secondary: {
      main: colors.orange.primary
    },
    cancel: {
      main: colors.orange.secondary
    },
    tertiary: {
      main: colors.gray.secondary
    },
    info: {
      main: colors.blue.secondary
    },
    disable: {
      main: colors.gray.primary
    },
    plane: {
      main: colors.white.primary
    },
    success: {
      main: colors.green.primary
    },
    background: {
      main: colors.background.primary
    },
    differentiation_background: {
      main: colors.background.secondary
    },
    switch: {
      main: colors.switch.primary
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem !important',
          fontFamily: 'Poppins_medium !important',
          textTransform: 'none !important',
          flexWrap: 'nowrap !important',
          borderRadius: '10px !important',
          padding: '9px 16px',
          boxShadow: 'none !important',
          ':hover': {
            border: 'none !important',
            transition: 'none !important'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins_medium',
          lineHeight: 1.25
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.black.tertiary,
          borderRadius: '20px !important',
          '&.Mui-checked': {
            color: colors.blue.secondary,
            borderRadius: '20px !important'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: 'Poppins_semiBold',
          color: colors.gray.tertiary,
          padding: '7.5px 5px 7.5px 7px !important',
          fontSize: '12px !important'
          // height: '1.9375em !important'
        },
        notchedOutline: {
          ':focus-visible': {
            outline: 'none !important'
            // border: 'unset !important'
          },
          borderWidth: '0px !important'
        },
        root: {
          // borderColor: colors.blue.tertiary,
          // borderRadius: '8px !important',
          ':focus-visible': {
            outline: 'none !important'
          },
          // ':hover': {
          //   outline: 'none !important'
          // }
          '& .MuiSelect-root': {
            '&.Mui-focused': {
              '&.MuiOutlinedInput-notchedOutline': {}
            }
            // ':hover': { borderColor: 'unset' }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins_Regular !important',
          fontSize: '12px !important'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 45,
          height: 25,
          padding: 0,
          borderRadius: '20px',
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 20
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)'
            }
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: '#fff',
              height: 24,
              width: 40,
              '& + .MuiSwitch-track': {
                opacity: 1,
                borderRadius: '20px'
              }
            }
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 20,
            height: 21,
            borderRadius: 10,
            transition:
              (['width'],
              {
                duration: 300
              })
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: 'rgba(0,0,0,.25)',
            boxSizing: 'border-box'
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: 'unset',
          fontFamily: 'Poppins_semibold !important',
          color: colors.gray.secondary,
          '&.Mui-active': {
            color: colors.blue.secondary,
            fontFamily: 'Poppins_semibold !important'
          }
        }
      }
    },

    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: colors.blue.secondary
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ':hover': {
            // border: 'none !important',
            '&.MuiOutlinedInput-notchedOutline': {
              border: 'none !important'
            }
          }
          // border:'10x s'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&	.Mui-selected': {
            MuiTypography: {
              root: {
                color: 'red !important'
              }
            }
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'unset !important',
          width: '400px !important',
          maxWidth: '400px !important',
          marginTop: '0px !important'
        }
      }
    }
  }
});
export default theme;
