/* eslint-disable no-constant-condition */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Grid } from '@mui/material';
// import { Controller, useForm } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import Typography from '../../atoms/Typography/Typography';
import CustomDropdown from '../../atoms/CustomDropdown/Dropdown';
import CustomTextField from '../../atoms/TextInput';
import './PackageDetails.css';
import CustomCheckbox from '../../atoms/CustomCheckbox/checkbox';
// import CustomButton from '../../atoms/CustomButton/Button';
// import { RelativeChargingEntries } from './entries';
import CustomButton from '../../atoms/CustomButton/Button';
import MultiSelect from '../../atoms/MultiSelectButton/MultiSelectButton';
import CustomTextArea from '../../atoms/CustomTextArea/TextArea';
import ErrorIndicator from '../../organisms/ErrorIndicator/ErrorIndicator';
import { REQUIRED, PATTERN } from '../../../utils/Constants/ErrorMsg';
import DepositRequirement from '../../organisms/DepositeRequirement/DepositeRequirement';
import StickyActionTable from '../../organisms/StickyActionTable';

/**
 *
 * @param {object} props - required props in AddPackageDetails
 * @returns {React.ReactElement} - returns the AddPackageDetails component
 */
const AddPackageDetails = (props) => {
  const { control, values, errors, Controller } = props;

  const [entry, setEntry] = React.useState();
  const [val, setVal] = React.useState([]);
  const [packagePrice, setPackagePrice] = React.useState('1');
  const [table, setTable] = React.useState();
  /**
   *
   * @param {*} e - passing data
   */
  const onClickList = (e) => {
    if (e?.id === '1') {
      setEntry(true);
    }
    if (e?.id === '2') {
      setEntry(false);
    }
  };
  const headCells = [
    'Charge Name/Criteria',
    'Encounter Type',
    'From Day',
    'To Day',
    'Qty Limit',
    'Amt Limit',
    'Limit Type',
    'Raise Order?',
    'Refundable?',
    'Order Catalog'
  ];

  const gridData = [
    {
      id: 111,
      isByCharge: true,
      name: 'charge name',
      type: 34,
      'from?': '1',
      to: '2',
      qty: '3',
      amt: '2.00',
      limit: 'rre',
      raise: 'rre',
      refund: 'yes',
      refund4: 'yes'
    },
    {
      id: 222,
      isByCharge: true,
      name: 'charge name',
      type: 34,
      'from?': '1',
      to: '2',
      qty: '3',
      amt: '2.00',
      limit: 'rre',
      raise: 'rre',
      refund: 'yes',
      refund4: 'yes'
    }
  ];
  /**
   *
   */
  const onClickHandle1 = () => {
    setTable('table');
    setEntry('table');
  };
  React.useEffect(() => {
    const arr = [
      {
        isDropdown: true,
        label: 'package Price',
        name: 'pkgpricetype',
        data: [
          { id: '1', value: 'lumpsum Basis' },
          { id: '2', value: 'Discount based' }
        ],
        breakpoint: 2
      },
      {
        isTextField: true,
        type: 'number',
        label: 'package Valid Days',
        name: 'pkgvaliddays',
        breakpoint: 2
      },
      {
        TextArea: true,
        type: 'text',
        pattern: /^(?!\s)(?!.*\s$)[A-Za-z0-9][A-Za-z0-9\_\.\-\,\s]*$/,
        breakpoint: 8,
        label: 'package Explanation Note',
        name: 'pkgexplnote'
      },
      {
        HR: true,
        breakpoint: 15
      },
      {
        Typography: true,
        breakpoint: 10.7,
        colorType: 'dark',
        text:
          (packagePrice.value === '1' &&
            packagePrice.name === 'packagePrice' &&
            'PACKAGE LUMPSUM BASED') ||
          (packagePrice.value === '2' &&
            packagePrice.name === 'packagePrice' &&
            'PACKAGE DISCOUT BASED')
      },
      {
        MultiSelect: true,
        breakpoint: 1,
        data: [
          { id: '1', list: 'By Charge Name' },
          { id: '2', list: 'By Charge Criteria' }
        ]
      },
      {
        Typography: entry === (true || false || 'table') ? true : false,
        breakpoint: 1.3,
        colorType: 'error',
        text: 'Cancel',
        action: 'Cancel'
      }
    ];
    if (entry === true) {
      arr.push(
        {
          isDropdown: true,
          label: 'Encounter Type',
          name: 'enctype',
          data: [
            { id: '1', value: 'aaa' },
            { id: '2', value: 'bbb' }
          ],
          breakpoint: 3
        },
        {
          isTextField: true,
          label: 'From Day',
          name: 'fromday',
          breakpoint: 1
        },

        {
          isTextField: true,
          label: 'To Day',
          name: 'today',
          breakpoint: 1
        },
        {
          isEmptySpace: true,
          breakpoint: 7
        },
        {
          Typography: true,
          breakpoint: 10.7,
          colorType: 'dark',
          text: 'BY CHARGE NAME'
        },
        {
          isEmptySpace: true,
          breakpoint: 10
        },
        {
          isDropdown: true,
          label: 'Charge Code & Discription',
          name: 'pkgchargecode',
          data: [
            { id: '1', value: 'aaa' },
            { id: '2', value: 'bbb' }
          ],
          breakpoint: 4
        },
        {
          isDropdown: true,
          label: 'Order Catelog',
          name: 'ordercatalogcode',
          data: [
            { id: '1', value: 'aaa' },
            { id: '2', value: 'bbb' }
          ],
          breakpoint: 2
        },
        {
          isCheckBox: true,
          name: 'israiseorder',
          label: 'Raise Order?',
          placement: 'top',
          breakpoint: 1
        },
        {
          isTextField: true,
          label: 'Quantity Limit',
          name: 'qtylimit',
          breakpoint: 1
        },
        {
          isTextField: true,
          label: 'Amount Limit',
          name: 'amtlimit',
          breakpoint: 1
        },
        {
          isDropdown: true,
          label: 'Limit Type',
          name: 'limittype',
          data: [
            { id: '1', value: 'aaa' },
            { id: '2', value: 'bbb' }
          ],
          breakpoint: 2
        },
        {
          isCheckBox: true,
          name: 'isrefundable',
          label: 'Refundable?',
          placement: 'top',
          breakpoint: 1
        },
        {
          isEmptySpace: true,
          breakpoint: 7.8
        },
        {
          saveButton: true,
          breakpoint: 4.2
        }
      );
    }
    if (entry === false) {
      arr.push(
        {
          isDropdown: true,
          label: 'Encounter Type',
          name: 'rvucode',
          data: [
            { id: '1', value: 'aaa' },
            { id: '2', value: 'bbb' }
          ],
          breakpoint: 3
        },
        {
          isTextField: true,
          label: 'From Day',
          name: 'fromday',
          breakpoint: 1
        },

        {
          isTextField: true,
          label: 'To Day',
          name: 'factor',
          breakpoint: 1
        },
        {
          labelBtn: true,
          breakpoint: 15,
          addNew: true
        }
      );
    }
    if (table === 'table') {
      arr.push({
        Table: true
      });
      if (entry === 'table') {
        arr.push({});
      }
    }
    setVal(arr);
  }, [entry, values, packagePrice, table]);
  return (
    <>
      {val?.map((keyValue) => {
        return (
          <>
            {keyValue?.isTextField && (
              <Grid item md={keyValue.breakpoint}>
                <Controller
                  // key={field.id}
                  rules={{ required: true }}
                  name={keyValue?.name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextField
                      label={keyValue?.label}
                      type={keyValue?.type}
                      onChange={(e) => onChange(e)}
                      value={value}
                      requiredField={keyValue.requiredField}
                      maxLength={keyValue.maxLength}
                    />
                  )}
                />
                {errors &&
                  errors[keyValue?.name] &&
                  errors[keyValue?.name]?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
              </Grid>
            )}
            {keyValue.TextArea && (
              <Grid item md={keyValue.breakpoint}>
                <Controller
                  name={keyValue?.name}
                  control={control}
                  rules={{ required: true, pattern: keyValue.pattern }}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextArea
                      label={keyValue.label}
                      type={keyValue?.type}
                      onChange={(e) => onChange(e)}
                      value={value}
                    />
                  )}
                />
                {errors &&
                  errors[keyValue?.name] &&
                  errors[keyValue?.name]?.type === 'required' && (
                    <ErrorIndicator errorMsg={REQUIRED} />
                  )}
                {errors &&
                  errors[keyValue?.name] &&
                  errors[keyValue?.name]?.type === 'pattern' && (
                    <ErrorIndicator errorMsg={PATTERN} />
                  )}
              </Grid>
            )}
            {keyValue.MultiSelect && (entry !== true || false) && (
              <Grid item md={keyValue.breakpoint}>
                <MultiSelect
                  listItemData={keyValue.data}
                  onClickList={onClickList}
                />
              </Grid>
            )}
            {keyValue.HR && (
              <Grid md={keyValue.breakpoint}>
                <hr />
              </Grid>
            )}
            {keyValue.labelBtn && (
              <Grid item md={keyValue.breakpoint}>
                <DepositRequirement
                  Controller={Controller}
                  control={control}
                  addNewControl={keyValue.addNew}
                  // handleBtn={handleBtn}
                  // handleInput={handleInput}
                  values="Add New Role Set"
                />
              </Grid>
            )}
            {keyValue?.isDropdown && (
              <Grid item md={keyValue.breakpoint}>
                <Grid>
                  <Controller
                    // key={field.id}
                    name={keyValue?.name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <CustomDropdown
                        data={keyValue?.data}
                        label={keyValue?.label}
                        value={value}
                        handleChange={onChange}
                        name={keyValue?.name}
                      />
                    )}
                  />
                  {errors &&
                    errors[keyValue?.name] &&
                    errors[keyValue?.name]?.type === 'required' && (
                      <ErrorIndicator errorMsg={REQUIRED} />
                    )}
                </Grid>
              </Grid>
            )}
            {keyValue?.isCheckBox && (
              <Grid item md={keyValue.breakpoint}>
                <Grid>
                  <Controller
                    // key={field.id}
                    name={keyValue?.name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomCheckbox
                        checked={value || ''}
                        onHandleChange={(e) => onChange(e)}
                        value={value}
                        checkboxLabel={keyValue.label}
                        placement={keyValue.placement}
                        customClasses="addCheck"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {keyValue.isEmptySpace && <Grid item md={keyValue.breakpoint} />}
            {keyValue.Typography && (
              <Grid item md={keyValue.breakpoint}>
                <Typography
                  colorType={keyValue.colorType}
                  text={keyValue.text}
                  type="link"
                />
              </Grid>
            )}
            {keyValue.Table && (
              <Grid className="TableContainer">
                <StickyActionTable
                  gridData={gridData}
                  headCells={headCells}
                  actions={['EDIT', 'VIEW', 'DELETE']}
                  actionHandler={(key, id) =>
                    console.log(key, id, 'actionHandler')
                  }
                  isStickyAction
                  enableSerial
                  enableAction
                />
              </Grid>
            )}
            {keyValue.saveButton && (
              <Grid item md={keyValue.breakpoint}>
                <div className="saveContainer">
                  <div className="multiSaveBtn">
                    <div className="save">
                      <CustomButton
                        type="differentiation_background"
                        btnTitle="Save as Default"
                        TitleColor="quinary"
                        onClickHandle={onClickHandle1}
                      />
                    </div>
                    <div className="save">
                      <CustomButton
                        type="differentiation_background"
                        btnTitle="Save & Add anorther"
                        TitleColor="quinary"
                        // onClickHandle={onClickHandle1}
                      />
                    </div>
                    <div className="adds">
                      <CustomButton btnTitle="Save" />
                    </div>
                  </div>
                </div>
              </Grid>
            )}
          </>
        );
      })}
    </>
  );
};
export default AddPackageDetails;
AddPackageDetails.propTypes = {
  control: PropTypes.node,
  values: PropTypes.arrayOf(PropTypes.objectOf)
  // onClickHandle1: PropTypes.func
};
AddPackageDetails.defaultProps = {
  control: <> </>,
  values: []
  // onClickHandle1: () => null
};
