/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Divider } from '@mui/material';
import { PropTypes } from 'prop-types';
import CustomTextField from '../../../../../../components/atoms/TextInput';
import Typography from '../../../../../../components/atoms/Typography/Typography';
import StaticBar from '../../../../../../components/atoms/StaticBar/staticBar';
import AddNew from '../../../../../../components/organisms/AddNew/AddNew';
import Entries from './entries';
import '../content.css';

/**
 *
 * @param {object} props --
 * @returns {React.ReactElement} - returns the general master content
 */
const MasterContent = (props) => {
  const {
    control,
    Controller,
    handleSubmit,
    form,
    onSubmit,
    errors,
    waatchField
  } = props;

  const barContent = [
    {
      id: 1,
      text: 'Code (20Chars)'
    },
    {
      id: 2,
      text: 'Short Description (60Chars)'
    },
    {
      id: 3,
      text: 'Long Description (200Chars)'
    },
    {
      id: 4,
      text: 'Status(Y/N)'
    }
  ];
  return (
    <div>
      <form id={form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Controller
              name="gentype"
              control={control}
              rules={{ required: true, pattern: /[A-Z]/ }}
              render={({ field: { onChange, value } }) => (
                <CustomTextField
                  label="Master Type"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={value}
                  requiredField
                />
              )}
            />
            {errors?.gentype && errors?.gentype?.type === 'required' && (
              <Typography
                colorType="error"
                text="Master Type is required"
                type="link"
              />
            )}
            {errors?.gentype && errors?.gentype?.type === 'pattern' && (
              <Typography
                colorType="error"
                text="Master Type text should be in CAPS"
                type="link"
              />
            )}
          </Grid>
          <Grid item md={4}>
            <Controller
              name="genname"
              control={control}
              rules={{
                required: true,
                pattern: /^[a-z]{1,}([A-Z][a-z]{1,}){1,}$/gm
              }}
              render={({ field: { onChange, value } }) => (
                <CustomTextField
                  label="Master Name"
                  type="text"
                  onChange={(e) => onChange(e)}
                  value={value}
                  requiredField
                />
              )}
            />
            {errors?.genname && errors?.genname?.type === 'required' && (
              <Typography
                colorType="error"
                text="Master Name is required"
                type="link"
              />
            )}
            {errors?.genname && errors?.genname?.type === 'pattern' && (
              <Typography
                colorType="error"
                text="Master Name  should be in Camel-Case"
                type="link"
              />
            )}
          </Grid>
          <Grid item />
        </Grid>
        <Grid container pt={2.5} pb={1}>
          <Typography type="caption" text="Standard Columns" />
        </Grid>
        <Divider className="borderBottom" />
        <Grid pt={1.5}>
          <StaticBar barContent={barContent} />
        </Grid>
        <Grid container />

        <div className="addNewDiv">
          <AddNew
            ArrayName="additionalcolumns"
            addNewHead="Additional Columns"
            Controller={Controller}
            control={control}
            divider
            errors={errors}
            entries={Entries}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            waatchField={waatchField}
          />
        </div>
      </form>
    </div>
  );
};

export default MasterContent;

MasterContent.propTypes = {
  Controller: PropTypes.node,
  control: PropTypes.node,
  handleSubmit: PropTypes.func,
  form: PropTypes.string,
  onSubmit: PropTypes.func
};
MasterContent.defaultProps = {
  Controller: <> </>,
  control: <> </>,
  handleSubmit: () => null,
  form: '',
  onSubmit: () => null
};
