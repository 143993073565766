/* eslint-disable no-useless-escape */
import {
  PATTERN,
  THREE_DIGITS_ONLY,
  FIVE_DIGITS_ONLY,
  INVALID_CASE
} from '../../../../utils/Constants/ErrorMsg';

export const RelativeChargingEntries = [
  {
    isComboText: true,
    isRequired: true,
    textLable: 'Description',
    dropDownLable: 'Base RV Unit',
    name: 'rvucode',
    dataList: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 7,
    dropBreak: 4,
    textBreak: 8,
    textDisable: true
  },

  {
    isTextField: true,
    isRequired: true,
    type: 'number',
    label: 'Relative Factor',
    name: 'factor',
    min: 0,
    maxLength: 10,
    pattern: /^\d{0,6}(\.\d{1,3})?$/,
    errorMsg: INVALID_CASE,
    breakpoint: 2
  },
  {
    isDelete: true,
    breakpoint: 2
  },
  {
    isEmptySpace: true,
    breakpoint: 1
  }
];
export const ResourceRequirementEntries = [
  {
    isDropdown: true,
    isRequired: true,
    label: 'Resource Role',
    name: 'resourcerole',
    data: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 2
  },
  {
    isTextField: true,
    isRequired: true,
    type: 'number',
    label: 'Max No Of Resources',
    name: 'maxnoofresources',
    maxLength: 5,
    errorMsg: FIVE_DIGITS_ONLY,
    min: 0,
    breakpoint: 5
  },
  {
    isCheckBox: true,
    name: 'ismandatory',
    label: 'Mandatory?',
    placement: 'top',
    breakpoint: 1
  },
  {
    isDelete: true,
    breakpoint: 1
  },
  {
    isEmptySpace: true,
    breakpoint: 3
  }
];
export const AssociatedCharges = [
  {
    isComboText: true,
    isRequired: true,
    textLable: 'Description',
    dropDownLable: 'Charge Code',
    name: 'chargecode',
    dataList: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 9,
    dropBreak: 3,
    textBreak: 9,
    textDisable: true
  },
  {
    isTextField: true,
    isRequired: true,
    label: 'Qty/Order Quantity',
    name: 'chargeqtyperord',
    breakpoint: 1.5,
    type: 'number',
    min: 0,
    maxLength: 3,
    errorMsg: THREE_DIGITS_ONLY
  },
  {
    isCheckBox: true,
    name: 'isreflex',
    label: 'Reflex?',
    placement: 'top',
    breakpoint: 1
  },
  {
    isDelete: true,
    breakpoint: 0.5
  }
];
export const PackageDetailsEntries = [
  {
    AddNewPackageDetails: true,
    RelativeChargingEntries: [
      {
        isDropdown: true,
        label: 'package Price',
        name: 'resourcerole',
        data: [
          { id: '1', value: 'lumpsum Basis' },
          { id: '2', value: 'Discount based' }
        ],
        breakpoint: 2
      },
      {
        isTextField: true,
        label: 'package Valid Days',
        name: 'factor',
        breakpoint: 2
      },
      {
        TextArea: true,
        breakpoint: 8,
        label: 'package Explanation Note'
      },
      {
        HR: true,
        breakpoint: 15
      },
      {
        Typography: true,
        breakpoint: 10.7
      },
      {
        MultiSelect: true,
        breakpoint: 1,
        data: [
          { id: '1', list: 'By Charge Name' },
          { id: '2', list: 'By Charge Criteria' }
        ]
      },
      {
        isDropdown: true,
        label: 'Encounter Type',
        name: 'rvucode',
        data: [
          { id: '1', value: 'aaa' },
          { id: '2', value: 'bbb' }
        ],
        breakpoint: 3
      },
      {
        isTextField: true,
        label: 'From Day',
        name: 'fromday',
        breakpoint: 1
      },

      {
        isTextField: true,
        label: 'To Day',
        name: 'factor',
        breakpoint: 1
      },
      {
        isEmptySpace: true,
        breakpoint: 7
      },
      {
        Typography: true,
        breakpoint: 10.7,
        text: 'BY CHARGE NAME'
      },
      {
        isEmptySpace: true,
        breakpoint: 10
      },
      {
        isDropdown: true,
        label: 'Charge Code & Discription',
        name: 'rvucode',
        data: [
          { id: '1', value: 'aaa' },
          { id: '2', value: 'bbb' }
        ],
        breakpoint: 4
      },
      {
        isDropdown: true,
        label: 'Order Catelog',
        name: 'rvucode',
        data: [
          { id: '1', value: 'aaa' },
          { id: '2', value: 'bbb' }
        ],
        breakpoint: 2
      },
      {
        isCheckBox: true,
        name: 'isreflex',
        label: 'Raise Order?',
        placement: 'top',
        breakpoint: 1
      },
      {
        isTextField: true,
        label: 'Quantity Limit',
        name: 'quantityLimit',
        breakpoint: 1
      },
      {
        isTextField: true,
        label: 'Amount Limit',
        name: 'amountLimit',
        breakpoint: 1
      },
      {
        isDropdown: true,
        label: 'Limit Type',
        name: 'rvucode',
        data: [
          { id: '1', value: 'aaa' },
          { id: '2', value: 'bbb' }
        ],
        breakpoint: 2
      },
      {
        isCheckBox: true,
        name: 'isreflex',
        label: 'Refundable?',
        placement: 'top',
        breakpoint: 1
      },
      {
        isEmptySpace: true,
        breakpoint: 7.8
      },
      {
        saveButton: true,
        breakpoint: 4.2
      }
    ]
  }
];
export const DepositRequirementEntries = [
  {
    labelBtn: true,
    breakpoint: 15
  },
  {
    CriteriaLabel: true
  }
];
export const SynonmysEntries = [
  {
    isComboText: true,
    isRequired: true,
    textLable: 'Short Description',
    dropDownLable: 'Synonym Type',
    name: 'synonymtype',
    dataList: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 6,
    dropBreak: 4,
    textBreak: 8,
    textDisable: true
  },
  {
    isComboText: true,
    isRequired: true,
    textLable: 'Long Description',
    dropDownLable: 'Language ID',
    name: 'langid',
    dataList: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 5.5,
    dropBreak: 3.5,
    textBreak: 8,
    textDisable: true
  },

  {
    isDelete: true,
    breakpoint: 0.5
  }
];
export const AliasCodeEntries = [
  {
    isDropdown: true,
    isRequired: true,
    label: 'Alias Type',
    name: 'aliastypeind',
    data: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 1.5
  },
  {
    isDropdown: true,
    isRequired: true,
    label: 'Alias Type Code',
    name: 'aliastypecode',
    data: [
      { id: '1', value: 'aaa' },
      { id: '2', value: 'bbb' }
    ],
    breakpoint: 1.5
  },
  {
    isTextField: true,
    isRequired: true,
    label: 'Alias Code',
    name: 'aliascode',
    id: 'aliasCode',
    breakpoint: 1,
    type: 'text',
    pattern: /^(?!\s)(?!.*\s$)[A-Za-z0-9][A-Za-z0-9\_\.\-\,\s]*$/,
    errorMsg: PATTERN,
    maxLength: 20
  },
  {
    isTextField: true,
    label: 'Alias Description',
    name: 'aliasshortdesc',
    breakpoint: 4.5,
    type: 'text',
    pattern: /^(?!\s)(?!.*\s$)[A-Za-z0-9][A-Za-z0-9\_\.\-\,\s]*$/,
    errorMsg: PATTERN,
    maxLength: 60
  },
  {
    isDatePicker: true,
    isRequired: true,
    name: 'efffr',
    label: 'Effective From',
    breakpoint: 1.5
  },
  {
    isDatePicker: true,
    isRequired: true,
    name: 'effto',
    label: 'Effective To',
    breakpoint: 1.5
  },
  {
    isDelete: true,
    breakpoint: 0.5
  }
];
