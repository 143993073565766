/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import customIcons from '../../../utils/icons/icons';
import './CustomButton.css';

/**
 *
 * @param  {object} props - required props in custom button component
 * @returns {React.ReactElement} - returns the button component
 */
const CustomButton = (props) => {
  const {
    defaultSelect,
    formBtn,
    variants,
    type,
    btnTitle,
    disabled,
    customClass,
    onClickHandle,
    startIcon,
    endIcon,
    iconType,
    size,
    TitleColor,
    id
  } = props;

  React.useEffect(() => {
    if (defaultSelect === 'true') {
      onClickHandle();
    }
  }, [defaultSelect]);

  /**
   *
   * @returns {object} returns icon
   */
  const getIconType = () => {
    switch (iconType) {
      case 'plus':
        return customIcons.Plus;
      case 'wordUpload':
        return customIcons.WordUpload;
      case 'buildings':
        return customIcons.Buildings;
      case 'delete':
        return customIcons.Delete;
      default:
        return 'plus';
    }
  };

  return (
    <Grid>
      {formBtn && (
        <button type="submit" form={id} className={`${customClass} formBtn`}>
          <Typography
            type="caption"
            text={btnTitle}
            colorType={TitleColor}
            customClass="btnTitle"
          />
        </button>
      )}
      {!formBtn && (
        <Button
          variant={variants}
          color={type}
          fullWidth
          disabled={disabled}
          disableRipple
          disableElevation
          disableFocusRipple
          className={customClass}
          onClick={onClickHandle}
          size={size}
        >
          {startIcon && (
            <img src={getIconType()} alt="upload" className="startIcon" />
          )}
          <Typography
            type="caption"
            text={btnTitle}
            colorType={TitleColor}
            customClass="btnTitle"
          />
          {endIcon && (
            <img src={getIconType()} alt="upload" className="endIcon" />
          )}
        </Button>
      )}
    </Grid>
  );
};

export default CustomButton;
CustomButton.propTypes = {
  formBtn: PropTypes.bool,
  variants: PropTypes.string,
  type: PropTypes.string,
  btnTitle: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  onClickHandle: PropTypes.func.isRequired,
  iconType: PropTypes.string,
  startIcon: PropTypes.bool,
  endIcon: PropTypes.bool,
  size: PropTypes.string,
  TitleColor: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  defaultSelect: PropTypes.bool
};

CustomButton.defaultProps = {
  formBtn: false,
  customClass: '',
  type: 'primary',
  iconType: '',
  variants: 'contained',
  startIcon: false,
  endIcon: false,
  size: '',
  TitleColor: 'quaternary',
  disabled: false,
  id: '',
  defaultSelect: false
};
