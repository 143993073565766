import { Divider, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
// import CustomDropdown from '../../../../components/atoms/CustomDropdown/Dropdown';
import Card from '../../atoms/Card/Card';
import buildings from '../../../assets/icons/buildings.svg';
import calender from '../../../assets/icons/calendar.svg';
import content from '../../../assets/icons/content.svg';
import ViewStatus from '../../../pages/ChargeMaster/AddChargeMaster/ChargeDefinition/Viewstatus';
import CustomButton from '../../atoms/CustomButton/Button';
import IconLabel from '../../atoms/IconLabel/iconLabel';
// import StatusComponent from '../../atoms/StatusComponent/Status';
import CustomTooltip from '../../atoms/ToolTip/Tooltip';
/**
 *
 * @param {object} props - required props in StatusBar
 * @returns {React.ReactElement} - returns the StatusBar component
 */
const StatusBar = (props) => {
  const { cardTitle, onOpenModal, onClickHandleAction } = props;
  const [open, setOpen] = React.useState(false);
  const datastatus = [
    {
      Image: buildings,
      head: 'Enterprise',
      body: 'AINQA - INDIA'
    },
    {
      Image: calender,
      head: 'Effective From',
      body: '12-06-2020'
    },
    {
      Image: calender,
      head: 'Effective To',
      body: '12-06-2020'
    },
    {
      Image: content,
      head: 'Reason',
      body: 'Quisque suscipit ipsum est, eu venenatis leo ornare ege.'
    }
  ];
  return (
    <Grid>
      <Card
        cardTitle={cardTitle}
        cardTitleType="caption"
        optionalHeader={
          <CustomButton
            size="small"
            btnTitle="View Status by Entity"
            variants="none"
            TitleColor="quinary"
            startIcon
            iconType="buildings"
            onClickHandle={() => onOpenModal(setOpen(true))}
          />
        }
      >
        {open && (
          <ViewStatus
            onCloseModal={(d) => setOpen(d)}
            onClickHandleAction={() => onClickHandleAction()}
          />
        )}
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          xs={9}
          className="statusgrid"
        >
          <IconLabel EntryData={datastatus} />
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          light
          flexItem
          sx={{ border: '0.5px solid #DEE5EC', mr: 3 }}
        />
        <Grid item xs={2} container justifyContent="flex-start">
          {/* <CustomTooltip tooltipTitle="status"> */}
          {/* <StatusComponent enable overAllStatus /> */}
          <CustomTooltip tooltipTitle="Status" enable overAllStatus />
          {/* </CustomTooltip> */}
        </Grid>
      </Card>
    </Grid>
  );
};
export default StatusBar;
StatusBar.propTypes = {
  onOpenModal: PropTypes.func,
  // OpenController: PropTypes.bool,
  //   headCells: PropTypes.arrayOf(PropTypes.objectOf),
  //   rowData: PropTypes.arrayOf(PropTypes.objectOf),
  cardTitle: PropTypes.string,
  onClickHandleAction: PropTypes.func
  //   isDrawer: PropTypes.bool
};
StatusBar.defaultProps = {
  onOpenModal: () => null,
  //   headCells: [],
  //   rowData: [],
  cardTitle: '',
  onClickHandleAction: () => null
  // OpenController: false
};
