import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import CustomTypography from '../../atoms/Typography/Typography';

/**
 *
 * @param {object} props - required props in TableHeader
 * @returns  {React.ReactElement} - returns the TableHeader component
 */
const TableHeader = (props) => {
  const { headCells, isStickyAction, enableSerial, enableAction } = props;

  return (
    <TableHead className="tableHead">
      <TableRow>
        {enableSerial && (
          <TableCell
            size="small"
            padding="normal"
            component="th"
            sx={{ fontFamily: 'Poppins_Regular' }}
            className="theadRow"
          >
            <CustomTypography type="caption" text="S.No" colorType="senary" />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            size="small"
            padding="normal"
            sx={{ fontFamily: 'Poppins_Regular' }}
            // className="theadRow"
            className={
              headCell === 'Charge Name/Criteria' ? 'chargeWidth' : 'theadRow'
            }
          >
            <CustomTypography
              type="caption"
              text={headCell}
              colorType="senary"
            />
          </TableCell>
        ))}
        {enableAction && (
          <TableCell
            size="small"
            padding="normal"
            align="right"
            sx={{
              borderLeft: isStickyAction && '1px solid #6a7888ab'
            }}
            className={`theadRow ${isStickyAction && 'stickyColumn'}`}
          >
            <CustomTypography type="caption" text="Action" colorType="senary" />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

TableHeader.propTypes = {
  isStickyAction: PropTypes.bool,
  enableAction: PropTypes.bool,
  enableSerial: PropTypes.bool,
  headCells: PropTypes.arrayOf(PropTypes.objectOf).isRequired
};
TableHeader.defaultProps = {
  enableAction: true,
  isStickyAction: true,
  enableSerial: false
};
