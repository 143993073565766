const defaultValues = {
  baseqty: 1,
  maxordqtyval: 1,
  maxdurnval: 1,
  iscaptureperfdept: true,
  enctypesallowed: [
    {
      enctype: `true,false,false,false,false`
    }
  ],
  maxtxnqty: 1,
  packageDetails: [{}],
  depositRequirement: [{}],
  synonmys: [{}],
  aliasCodeMap: [{}],
  relchargebasis: [
    {
      rvucode: '',
      factor: '1'
    }
  ],
  requiredresources: [
    {
      resourcerole: '',
      maxnoofresources: '1',
      ismandatory: false
    }
  ],
  assoccharges: [
    {
      chargecode: '',
      chargeqtyperord: '',
      isreflex: false
    }
  ],
  chargesynonyms: [
    {
      synonymtype: '',
      langid: '',
      shortdesc: '',
      longdesc: ''
    }
  ],
  aliasservmapping: [
    {
      aliastypeind: '',
      aliastypecode: '',
      aliascode: '',
      aliasshortdesc: '',
      efffr: new Date(),
      effto: new Date()
    }
  ],
  ER: true,
  DC: false,
  EX: false,
  IP: false,
  OP: false
};

export default defaultValues;
