/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import './pagination.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomTypography from '../../../atoms/Typography/Typography';

/**
 *
 * @param {*} props -- Multiple Customization
 * @returns {React.ReactElement} -- Custom pagination component
 */
const Pagination = (props) => {
  const { pageCount, totalCount, getRow, getPageNo } = props;

  const paginationLastCount = pageCount;
  const [isActive, setIsActive] = React.useState(true);
  const [thisPagination, setThisPagination] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState();
  const [startCount, setStartCount] = React.useState(1);
  const [endCount, setEndCount] = React.useState(5);
  const [perPage, setPerPage] = React.useState(5);

  useEffect(() => {
    const InitialLimit = Number(totalCount) / 5;

    setPageLimit(Math.ceil(InitialLimit));
  }, [totalCount]);

  /**
   *
   * @param {*} val - passing value
   */
  function myFunction(val) {
    const x = document.getElementById('select').value;
    getRow(x);
    const limit = Number(totalCount) / Number(val.target.value);
    setPerPage(Number(val.target.value));
    setPageLimit(Math.ceil(limit));
    const end =
      totalCount < Number(val.target.value) * thisPagination
        ? totalCount
        : Number(val.target.value) * thisPagination;
    const start =
      Number(val.target.value) * thisPagination - Number(val.target.value) === 0
        ? 1
        : Number(val.target.value) * thisPagination - Number(val.target.value);
    setEndCount(end);
    setStartCount(start);
  }
  /**
   *
   */
  const options = [
    { id: 1, value: 5 },
    { id: 2, value: 10 },
    { id: 3, value: 20 },
    { id: 4, value: 25 }
  ];
  /**
   *
   * @param {*} key --
   */
  function onPageSelection(key) {
    setIsActive(!isActive);
    setThisPagination(key);
    const end = totalCount < perPage * key ? totalCount : perPage * key;
    const start = perPage * key - perPage === 0 ? 1 : perPage * key - perPage;
    setEndCount(end);
    setStartCount(start);
  }
  return (
    <div className="footer">
      <div>
        <CustomTypography
          customClass="page"
          text={`${startCount} - ${endCount}`}
        />
        <span className="totalPages">
          <CustomTypography
            customClass="totalPages"
            text={` of ${totalCount}`}
          />
        </span>
      </div>
      <div className="tableFooter">
        <CustomTypography customClass="pageText" text="Per Page" />
        <div className="paginationDropDownSection">
          <select
            // name="select"
            id="select"
            className="paginationDropDown"
            onClick={myFunction}
          >
            {options.map((item) => {
              return (
                <option value={item.value} key={item.id}>
                  <CustomTypography
                    text={item.value}
                    type="caption"
                    customClass="option"
                  />
                </option>
              );
            })}
          </select>
        </div>
        <div style={{ borderRight: 'solid 1px #ddd', padding: '7px' }} />
        <div className="paginationCount">
          <div className="arrowIcon">
            <ArrowBackIosIcon style={{ fontSize: '12px' }} />
          </div>
          {[...Array(pageLimit).keys()].map((val) => {
            const key = val + 1;
            return (
              <Grid
                key={key}
                onClick={() => {
                  getPageNo(key);
                  onPageSelection(key);
                }}
              >
                <CustomTypography
                  customClass="pageText"
                  customStyle={{
                    paddingLeft: '15px',
                    cursor: 'pointer',
                    color:
                      thisPagination === key && isActive
                        ? '#2A60BC'
                        : '#6A7888',
                    fontFamily:
                      thisPagination === key && isActive
                        ? 'Poppins_semibold'
                        : 'Poppins_Regular'
                  }}
                  text={key}
                />
              </Grid>
            );
          })}
          {pageLimit > 5 && (
            <>
              ....
              <CustomTypography
                customClass="pageText"
                customStyle={{ paddingLeft: '15px' }}
                text={pageLimit}
              />
            </>
          )}
          <div className="arrowIcon">
            <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  getRow: PropTypes.func.isRequired,
  getPageNo: PropTypes.func.isRequired
};
