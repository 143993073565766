/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import Card from '../../Card/Card';
import customIcons from '../../../../utils/icons/icons';
import colors from '../../../../utils/colors/colors';
import CustomDatePicker from '../../Pickers/DatePicker';
import ErrorIndicator from '../../../organisms/ErrorIndicator/ErrorIndicator';
import CustomDropdown from '../../CustomDropdown/Dropdown';
import IconLabel from '../../IconLabel/iconLabel';
import { REQUIRED } from '../../../../utils/Constants/ErrorMsg';
import './TooltipContent.css';
/**
 *
 * @param {object} props - required props in TooltipContent
 * @returns {React.ReactElement} - returns the tooltip content
 */
const TooltipContent = (props) => {
  const { contentTitle, onShowOrganization, onCheck } = props;
  const [addReason, setAddReason] = useState(false);
  const {
    control,
    formState: { errors }
  } = useForm({});
  const dataList = [
    { id: 1, value: 'Reason 1' },
    { id: 2, value: 'Reason 2' }
  ];
  const EntryData = [
    {
      Image: customIcons.Calender,
      head: 'Effective From',
      body: '02-02-2022'
    },
    {
      Image: customIcons.Calender,
      head: 'Effective To',
      body: '-'
    }
  ];
  const reason = [
    {
      Image: customIcons.Content,
      head: 'Reason',
      body: 'Quisque suscipit ipsum est, eu venenatis leo ornare ege.'
    }
  ];
  /**
   *
   */
  const onEdit = () => {
    setAddReason(true);
  };
  /**
   *
   */
  const onCancel = () => {
    setAddReason(false);
  };
  return (
    <div>
      <Card
        cardTitle={contentTitle}
        cardTitleType="caption"
        toolTip
        optionalHeader={
          <div>
            {!addReason && (
              <>
                <img
                  src={customIcons.Edit}
                  alt="edit"
                  className="toolEdit"
                  onClick={() => onEdit()}
                  aria-hidden
                />
                <img
                  src={customIcons.Organization}
                  alt="organization"
                  className="toolIcon"
                  onClick={() => onShowOrganization()}
                  aria-hidden
                />
              </>
            )}
            {addReason && (
              <div className="reasonHead">
                <span className="divCancel">
                  <img
                    src={customIcons.Cross}
                    alt="cancel"
                    className="cancelPic"
                    onClick={() => onCancel()}
                    aria-hidden
                  />
                </span>
                <span className="divCheck">
                  <img
                    src={customIcons.Checked}
                    alt="check"
                    onClick={() => onCheck()}
                    aria-hidden
                  />
                </span>
              </div>
            )}
          </div>
        }
      >
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            border: '1px solid',
            borderColor: colors.blue.tertiary,
            width: '100%'
          }}
        />
        <Grid pt={2}>
          {!addReason && (
            <Grid>
              <Grid>
                <IconLabel EntryData={EntryData} />
              </Grid>
              <Grid pt={1} pb={1}>
                <IconLabel EntryData={reason} />
              </Grid>
            </Grid>
          )}
          {addReason && (
            <Grid>
              <Grid container item md={12} spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="effectiveFrom"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        label="Effective From"
                        onHandleChange={(e) =>
                          onChange(moment(e).format('YYYY-MM-DD'))
                        }
                        value={value}
                        disabled
                      />
                    )}
                  />
                  {errors?.effectiveFrom &&
                    errors?.effectiveFrom?.type === 'required' && (
                      <ErrorIndicator errorMsg={REQUIRED} />
                    )}
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="effectiveTo"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        label="Effective To"
                        onHandleChange={(e) =>
                          onChange(moment(e).format('YYYY-MM-DD'))
                        }
                        value={value}
                      />
                    )}
                  />
                  {errors?.effectiveTo &&
                    errors?.effectiveTo?.type === 'required' && (
                      <ErrorIndicator errorMsg={REQUIRED} />
                    )}
                </Grid>
              </Grid>
              <Grid pt={2} pb={2}>
                <Controller
                  name="reason"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      label="Reason"
                      data={dataList}
                      value={value || ''}
                      handleChange={onChange}
                      name="reason"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default TooltipContent;
TooltipContent.propTypes = {
  contentTitle: PropTypes.string,
  onShowOrganization: PropTypes.func,
  onCheck: PropTypes.func
};
TooltipContent.defaultProps = {
  contentTitle: '',
  onShowOrganization: () => null,
  onCheck: () => null
};
