/* eslint-disable react/prop-types */
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-returns-check */
/* eslint-disable jsdoc/require-returns */
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 *--
 */
const CustomizedSnackBars = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { type, text, open, handleClose } = props;
  //   const [open, setOpen] = React.useState(false);
  /**
   *
   * @param {*} event
   * @param {*} reason
   * @returns
   */

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert severity={type} sx={{ width: '100%' }}>
          {text}
        </Alert>
      </Snackbar>
      {/* <Alert severity={type}>This is an error message!</Alert> */}
      {/* <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
};

export default CustomizedSnackBars;
