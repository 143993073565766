import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import colors from '../../../utils/colors/colors';
import './Typography.css';

/**
 *
 * @param {object} props - required props in custom typography
 * @returns {React.ReactElement} - returns the typography component
 */
const CustomTypography = (props) => {
  const { type, text, customClass, colorType, customStyle, requiredField } =
    props;

  /**
   * @name getFontType
   * @returns {React.ReactElement}  - returns the required  font type of text
   */
  const getFontType = () => {
    switch (type) {
      case 'heading':
        return 'h6';
      case 'title':
        return 'subtitle1';
      case 'caption':
        return 'subtitle2';
      case 'link':
        return 'caption';
      case 'error':
        return 'caption';
      default:
        return 'title';
    }
  };
  /**
   *@name getColorType
   *@returns {React.ReactElement}- returns the required type of text  colors
   */
  const getColorType = () => {
    switch (colorType) {
      case 'primary':
        return colors.blue.quaternary;
      case 'secondary':
        return colors.gray.tertiary;
      case 'tertiary':
        return colors.gray.primary;
      case 'quaternary':
        return colors.white.primary;
      case 'quinary':
        return colors.blue.secondary;
      case 'senary':
        return colors.gray.secondary;
      case 'active':
        return colors.green.primary;
      case 'inActive':
        return colors.orange.primary;
      case 'dark':
        return colors.black.secondary;
      case 'background':
        return colors.background.secondary;
      case 'error':
        return colors.red.primary;
      default:
        return colors.blue.quaternary;
    }
  };
  return (
    <Typography
      variant={getFontType(type)}
      color={getColorType(colorType)}
      className={`${customClass} ${type === 'error' && 'errorText'}`}
      sx={customStyle}
    >
      {text}
      {requiredField && <span className="required">*</span>}
    </Typography>
  );
};

export default CustomTypography;
CustomTypography.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colorType: PropTypes.string,
  customClass: PropTypes.string,
  customStyle: PropTypes.shape(),
  requiredField: PropTypes.bool
};
CustomTypography.defaultProps = {
  type: '',
  colorType: '',
  customClass: '',
  customStyle: {},
  requiredField: false
};
