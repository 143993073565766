import React from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import CustomModal from '../../../../../components/molecules/Modal/modal';
import MasterContent from './MasterContent/MasterContent';
import CustomButton from '../../../../../components/atoms/CustomButton/Button';
import Toaster from '../../../../../components/atoms/Toaster/Toaster';
/**
 *
 * @param {object} props - required props in modal content
 * @returns {React.ReactElement}- returns the master modal content
 */
const NewGeneralMaster = (props) => {
  const { onCloseModal, onClickHandleAction } = props;
  const [msg, setMsg] = React.useState('');
  const [type, setType] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({});
  const waatchField = watch();
  /**
   *
   * @param {*} data - passing data
   */
  const onsubmitData = (data) => {
    const url = process.env.REACT_APP_URL2;
    reset();
    const CustomData = data;
    CustomData.issystemdefined = true;
    if (CustomData?.additionalcolumns.length === 0)
      delete CustomData.additionalcolumns;
    axios({
      method: 'post',
      url: `${url}/createGeneralMaster`,
      data: CustomData,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response) {
          setType('success');
          setMsg('Data added successfully');
          setSuccessMsg(true);
        }
      })
      .catch((err) => {
        setMsg(err);
        setType('failure');
        setSuccessMsg(true);
      });
  };
  /**
   * @param {*} event - passing event
   * @param {*} reason - passing reason
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessMsg(false);
  };
  return (
    <div>
      {successMsg && (
        <Toaster
          type={type}
          open={successMsg}
          text={msg}
          handleClose={handleClose}
        />
      )}
      <CustomModal
        openModal
        modalTitle="New General Master"
        fontSize="14px"
        modalContent={
          <MasterContent
            errors={errors}
            control={control}
            Controller={Controller}
            handleSubmit={handleSubmit}
            form="GeneralMaster"
            waatchField={waatchField}
            onSubmit={(data) => onsubmitData(data)}
          />
        }
        onClose={(data) => onCloseModal(data)}
        actionType={
          <Grid p={1.1} display="flex">
            <CustomButton
              id="GeneralMaster"
              formBtn
              btnTitle="Save"
              onClickHandle={() => onClickHandleAction()}
            />
          </Grid>
        }
      />
    </div>
  );
};

export default NewGeneralMaster;
NewGeneralMaster.propTypes = {
  onCloseModal: PropTypes.func,
  onClickHandleAction: PropTypes.func
};
NewGeneralMaster.defaultProps = {
  onCloseModal: () => null,
  onClickHandleAction: () => null
};
