import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Grid, FormGroup, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import './checkbox.css';

/**
 * @name CustomCheckbox
 * @param {*} props -- required props
 * @returns {React.ReactElement} --
 */
const CustomCheckbox = (props) => {
  const {
    onHandleChange,
    value,
    checked,
    name,
    checkboxLabel,
    placement,
    disabled,
    customClasses
  } = props;
  return (
    <Grid>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          className={`${placement === 'top' && 'alignTop'} checkAlign`}
          disabled={disabled}
          control={
            <Checkbox
              name={name}
              onChange={(e) => onHandleChange(e)}
              value={value || ''}
              checked={checked}
              disableRipple
              className={`${customClasses} check`}
            />
          }
          label={
            <Typography
              type="caption"
              text={checkboxLabel}
              customClass="checkLabelText"
              colorType="senary"
            />
          }
          labelPlacement={placement}
        />
      </FormGroup>
    </Grid>
  );
};
export default CustomCheckbox;
CustomCheckbox.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string,
  placement: PropTypes.string,
  customClasses: PropTypes.string,
  disabled: PropTypes.bool
};
CustomCheckbox.defaultProps = {
  checkboxLabel: '',
  placement: 'top',
  customClasses: '',
  disabled: false
};
