import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import SiderList from './listItems';
import './SideNav.css';

/**
 * @param {*} props - props
 * @name CustomTextField
 * @returns {React.ReactElement} return the TextField Component
 */
const CustomNavbar = (props) => {
  const { closeNavbar } = props;
  const [closeMenu, setCloseMenu] = useState(null);
  const [menu, setMenu] = useState([]);
  const url = process.env.REACT_APP_URL;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${url}/read_documents`,

      data: {
        db_name: 'RCM',
        entity: 'sideNav',
        return_fields: 'sideNav'
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      setMenu(response?.data.result[0].children);
    });
  }, [url]);

  /**
   *
   */
  const toggleSidebar = () => {
    document.getElementById('mySidebar').style.width = '16rem';
    setCloseMenu(true);
  };
  /**
   *
   */
  const toggleSidebarClose = () => {
    document.getElementById('mySidebar').style.width = '3.5rem';
    setCloseMenu(false);
    closeNavbar(false);
  };

  return (
    <div>
      <div
        id="mySidebar"
        className="sidebar"
        onMouseOver={() => toggleSidebar()}
        onMouseOut={() => toggleSidebarClose()}
        onFocus={() => null}
        onBlur={() => null}
      >
        <div>
          <SiderList menu={menu} closeMenu={closeMenu} />
        </div>
      </div>
    </div>
  );
};
export default CustomNavbar;
CustomNavbar.propTypes = {
  closeNavbar: PropTypes.func
};
CustomNavbar.defaultProps = {
  closeNavbar: () => {}
};
