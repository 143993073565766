/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import React from 'react';
import CustomTypography from '../../atoms/Typography/Typography';
/**
 *
 * @param {object} props - required props in ErrorIndicator
 * @returns {React.ReactElement} - returns the ErrorIndicator component
 */
const ErrorIndicator = (props) => {
  const { errorMsg } = props;
  return (
    <div style={{ color: 'red' }}>
      <CustomTypography text={errorMsg} type="error" />
    </div>
  );
};
export default ErrorIndicator;
ErrorIndicator.propTypes = {
  errorMsg: PropTypes.string
};
ErrorIndicator.defaultProps = {
  errorMsg: ''
};
