/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomTextField from '../../atoms/TextInput';
import CustomDropdown from '../../atoms/CustomDropdown/Dropdown';
import CustomSwitch from '../../atoms/CustomSwitch/switch';
import Typography from '../../atoms/Typography/Typography';
import CustomButton from '../../atoms/CustomButton/Button';

/**
 *
 * @param {object} props - required props in DrawerContent
 * @returns {React.ReactElement} - returns the drawer content
 */
const DrawerContent = (props) => {
  const {
    control,
    Controller,
    handleSubmit,
    onSubmit,
    errors,
    entries,
    additionalColumn,
    additionalColumnsComponent
  } = props;
  return (
    <div>
      <div className="formField">
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextField
              label="Code"
              type="text"
              onChange={(e) => onChange(e)}
              value={value}
            />
          )}
        />
      </div>
      <div className="formField">
        <Controller
          name="shortdesc"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <CustomTextField
                label="Short description"
                type="text"
                onChange={(e) => onChange(e)}
                value={value}
              />
            );
          }}
        />
      </div>
      <div className="formField">
        <Controller
          name="display"
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextField
              label="Long Description"
              type="text"
              onChange={(e) => onChange(e)}
              value={value}
            />
          )}
        />
      </div>

      {additionalColumnsComponent &&
        additionalColumnsComponent.map((keyValue, index) => {
          const key = index;
          return (
            <Grid container item md={12} key={key}>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Grid item md={12} className="space">
                    {keyValue.display === 'Text Box' && (
                      <Grid sx={{ width: '100%' }}>
                        <CustomTextField
                          label={additionalColumn[index]?.columnname}
                          // type={keyValue.textInputType}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                        />
                      </Grid>
                    )}
                    {keyValue.display === 'List' && (
                      <CustomDropdown
                        data={keyValue.data}
                        label={keyValue.label}
                        handleChange={(e) => onChange(e)}
                        value={value}
                        disabled={keyValue.disabled}
                      />
                    )}

                    {keyValue.display === 'switch' && (
                      <Grid sx={{ pt: 1 }}>
                        <CustomSwitch
                          name={keyValue.name}
                          onChange={(e) => onChange(e)}
                          value={value}
                          switchColor="info"
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                name={additionalColumn[index]?.columnname}
              />
            </Grid>
          );
        })}
      <Grid container justifyContent="flex-end" className="drawerAction">
        <CustomButton
          btnTitle="Save"
          variants="contained"
          type="info"
          onClickHandle={handleSubmit(onSubmit)}
        />
      </Grid>
      {/* </form> */}
    </div>
  );
};

export default DrawerContent;
DrawerContent.propTypes = {
  Controller: PropTypes.node,
  control: PropTypes.node,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func
};
DrawerContent.defaultProps = {
  Controller: <> </>,
  control: <> </>,
  handleSubmit: () => null,
  onSubmit: () => null
};
