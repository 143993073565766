import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
// import { Category } from '@mui/icons-material';
/**
 *
 * @param {object} props - required props in PersentageInput
 * @returns {React.ReactElement} - returns the PersentageInput component
 */
const PersentageInput = (props) => {
  const {
    Controller,
    label,
    // onChange,
    type,
    // value,
    disabled,
    error,
    errorMsg,
    customClass,
    requiredField,
    maxLength,
    placeholder,
    Category,
    control
  } = props;
  return (
    <Box>
      <Box>
        <Typography
          type="caption"
          text={label}
          customClass="labelText"
          colorType="senary"
          requiredField={requiredField}
        />
      </Box>
      <Box>
        <Controller
          name="deppercval"
          control={control}
          render={({ field: { onChange, value } }) => (
            <input
              type={type}
              value={value || ''}
              error={error}
              disabled={disabled}
              onChange={(e) => onChange(e.target.value)}
              className={`${error && 'errorBox'} inputBox  ${customClass} ${
                disabled && 'disable'
              }`}
              errorMsg={errorMsg}
              maxLength={maxLength}
              min="0"
              placeholder={placeholder.concat('       ', Category)}
            />
          )}
        />
        {error && <div className="errorBo">{errorMsg}</div>}
      </Box>
    </Box>
  );
};
export default PersentageInput;
PersentageInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  // onChange: PropTypes.func,
  error: PropTypes.bool,
  type: PropTypes.string,
  // value: PropTypes.string,
  disabled: PropTypes.bool,
  //   pattern: PropTypes.string,
  errorMsg: PropTypes.string,
  customClass: PropTypes.string,
  requiredField: PropTypes.bool,
  placeholder: PropTypes.string,
  Category: PropTypes.string,
  control: PropTypes.string,
  Controller: PropTypes.string
};
PersentageInput.defaultProps = {
  // onChange: () => {},
  type: 'text',
  disabled: false,
  error: false,
  label: '',
  maxLength: '',
  //   pattern: '',
  errorMsg: '',
  customClass: '',
  // value: '',
  requiredField: false,
  placeholder: '',
  Category: '',
  control: '',
  Controller: ''
};
