/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableBody, TableRow } from '@mui/material';
import CustomTypography from '../../atoms/Typography/Typography';
import customIcons from '../../../utils/icons/icons';

/**
 *
 * @param {object} props required props in ActionItem
 * @returns {React.ReactElement} - returns the ActionItem component
 */
const ActionItem = (props) => {
  const { icons, actionHandler, isByCharge } = props;

  /**
   *@name getActionIcon
   * @param {string} key - passing key value
   * @returns {Function} - return the specific icon
   */
  const getActionIcon = (key) => {
    let value;
    switch (key) {
      case 'EDIT':
        value = customIcons.Edit;
        break;
      case 'VIEW':
        value = isByCharge ? customIcons.Calender : customIcons.Plus;
        break;
      case 'DELETE':
        value = customIcons.Delete;
        break;
      default:
        break;
    }
    return value;
  };
  return (
    <span className="actionItems">
      {icons?.map((icon, iconIndex) => {
        const iconKey = iconIndex;
        return (
          <img
            key={iconKey}
            src={getActionIcon(icon)}
            alt={icon.toLowerCase()}
            aria-hidden
            className="actionIcon"
            onClick={() => actionHandler(icon)}
          />
        );
      })}
    </span>
  );
};

/**
 *
 * @param {object} props - required props in TableBodySection
 * @returns  {React.ReactElement} - returns the TableBodySection component
 */
const TableBodySection = (props) => {
  const {
    gridData,
    isStickyAction,
    actions,
    actionHandler,
    enableSerial,
    // eslint-disable-next-line no-unused-vars
    enableAction
  } = props;

  return (
    <TableBody className="tableBody">
      {gridData?.map((row, index) => {
        const key = index + 1;
        const rowId = row?.id;
        const isByCharge = !!row?.isByCharge;
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={key}>
            {enableSerial && (
              <TableCell component="th" className="tableCell">
                <CustomTypography type="caption" text={key} />
              </TableCell>
            )}
            {Object.entries(row)?.map(([key, value], cellIndex) => {
              const cellKey = cellIndex;
              const hide = ['id', 'isByCharge'].includes(key);
              return (
                <>
                  {!hide && (
                    <TableCell
                      component="th"
                      id={cellKey}
                      className="tableCell"
                    >
                      <CustomTypography type="caption" text={value} />
                    </TableCell>
                  )}
                </>
              );
            })}
            {enableAction && (
              <TableCell
                size="small"
                align="right"
                padding="normal"
                sx={{
                  fontFamily: 'Poppins_Regular',
                  backgroundColor: '#ffffff',
                  position: isStickyAction && 'sticky',
                  borderLeft: isStickyAction && '1px solid #6a7888ab'
                }}
                className={`tableCell ${isStickyAction && 'stickyColumn'}`}
              >
                <ActionItem
                  icons={actions}
                  isByCharge={isByCharge}
                  actionHandler={(type) => actionHandler(type, rowId)}
                />
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableBodySection;

TableBodySection.propTypes = {
  isStickyAction: PropTypes.bool,
  enableAction: PropTypes.bool,
  enableSerial: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.string),
  gridData: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  actionHandler: PropTypes.func
};
TableBodySection.defaultProps = {
  actionHandler: () => null,
  enableAction: true,
  isStickyAction: true,
  enableSerial: false,
  actions: ['EDIT', 'VIEW', 'DELETE']
};

ActionItem.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
  isByCharge: PropTypes.bool,
  actionHandler: PropTypes.func
};
ActionItem.defaultProps = {
  icons: ['EDIT', 'VIEW', 'DELETE'],
  isByCharge: true,
  actionHandler: () => null
};
